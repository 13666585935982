// @ts-check
import {Workbook, ValueType as excelValueType} from 'exceljs';
import {ElMessage} from 'element-plus';
import dayjs from 'dayjs';
// import dayjs from 'dayjs';
/**
  @typedef {object} range
  @property {number} top
  @property {number} left
  @property {number} bottom
  @property {number} right
 */
/**
  @typedef {object} column
  @property {string} key
  @property {string} [header]
  @property {cTyp} type
  @property {Number} [width]
  @property {string|Boolean} [default]
  @property {string} [dateFmt]
  @property {Boolean} [filter=false]
  @property {string[]} [selctions]
 */
/**
  @typedef {object} sheetDefined
  @property {string} name 名称
  @property {range} range sheet范围
  @property {column[]} columns 列定义
 */
/**
  @typedef {object} Model
  @property {sheetDefined} sheetDefined
  @property {Object} dataDefined
  @property {string} dataDefined.name
  @property {column[]} dataDefined.columns
 */

/**
 * 作业对象
 * @typedef {Object} Job
 * @property {string} code - 作业代码
 * @property {string} product_code - 产品代码
 * @property {string} process_code - 工序名称
 * @property {number} quantity - 作业数量 整数
 * @property {string} earliest - 最早开始时间  YYYY-MM-DD
 * @property {string} latest - 最晚完成时间  YYYY-MM-DD
 */

/**
 * 时间表信息
 * @typedef {Object} ScheduleInfo
 * @property {string} date - 锁定日期  YYYY-MM-DD
 * @property {string} pre - 准备开始时间  hh:mm:ss
 * @property {string} run - 运行开始时间  hh:mm:ss
 * @property {string} end - 结束时间  hh:mm:ss
 */
/**
 * 作业锁定信息
 * @typedef {Object} LockInfo
 * @property {string} job_code - 被锁定作业的代码
 * @property {string} machine - 被锁定的机器代码
 * @property {ScheduleInfo[]} schedule - 锁定时间表
 */

/**
 * 机器状态
 * @typedef {Object} MachineStatus
 * @property {string|null} last_code - 开始日期之前该机已排产的最后一个job null,<product_code::process_name
 */
/**
 * 提交计算参数
 * @typedef {Object} calcPostData
 * @property {Object} options - 配置项
 * @property {string} options.开始日期 - 调度的开始日期 日期格式为 YYYY-MM-DD
 * @property {Object.<string, MachineStatus>} machines - 机台最近排产的状态 product_code::process_name
 * @property {Job[]} jobs - 待完成的任务列表
 * @property {LockInfo[]} locks - 任务锁定信息
 */

/**
 * 产品信息
 * @typedef {Object} ProductInfo
 * @property {string} code - 产品代码
 * @property {number} id - 产品ID
 * @property {string} state - 产品状态
 * @property {string} name - 产品名称
 * @property {Object[]} craft - 工艺流程信息
 * @property {string} craft.name - 工序名称
 * @property {string} craft.cate_code - 工序代码
 * @property {string} craft.cate_name  - 工序类别名称
 * @property {string[]} craft.last_process  - 工序信息
 * @property {number} craft.output_ratio - 产出比
 */
/**
 * 表示机器信息的类
 * @typedef {Object} MachineInfo
 * @property {string} code - 机器代码
 * @property {number} id - 机器ID
 * @property {string} name - 机器名称
 */
/**
 * 排产结果
 * @typedef {Object} ScheduleResult
 * @property {string} job_code - 任务单号
 * @property {number} quantity - 数量
 * @property {string} product - 产品名称
 * @property {string} remark - 备注信息
 * @property {ProductInfo} [product_info] - 产品信息
 * @property {string} machine - 使用的机器名称|或者机器代码
 * @property {MachineInfo} [machine_info] - 机器信息
 * @property {string} pre - 开始时间 hh:mm:ss | YYYY-MM-DD hh:mm:ss
 * @property {string} run - 运行时间 hh:mm:ss | YYYY-MM-DD hh:mm:ss
 * @property {string} end - 结束时间 hh:mm:ss | YYYY-MM-DD hh:mm:ss
 * @property {string} date - 日期 YYYY-MM-DD
 * @property {string} process_name - 工序名称
 * @property {string} process_cate_name - 工序类别名称
 * @property {Array} [error] - 错误信息列表
 * @property {string} [serial_number] - 序列号
 * @property {string} [order_code] -订单号|任务号(ERP)
 * @property {string|null} [task_id] - erp传递过来的任务ID(当前为空)
 */
/**
 * 订单信息对象类型定义
 * @typedef {Object} OrderResult
 * @property {string} order_code - 订单编号
 * @property {string|null} [task_id] - erp传递过来的任务ID(当前为空)
 * @property {string} product - 产品名称
 * @property {ProductInfo} product_info - 产品名称
 * @property {number} quantity - 产品数量
 * @property {string} earliest - 最早日期 YYYY-MM-DD
 * @property {string} latest - 最晚日期 YYYY-MM-DD
 * @property {string} shipment_date - 交货日期 YYYY-MM-DD
 * @property {'是'|'否'|1|0} is_finished - 是否完成('是'或'否' 1 0)
 * @property {string} remark - 备注信息
 * @property {Array} error - 错误信息列表(当前为空)
 * @property {string} serial_number - 序列号
 */
/**
 * 任务定义 JOB
 * @typedef {Object} JobResult
 * @property {string} order_code - 订单号
 * @property {string} job_code - 任务号
 * @property {string|null} [task_id] - erp传递过来的任务ID（当前为空）
 * @property {string} product - 产品名称
 * @property {ProductInfo} product_info - 产品名称
 * @property {number} quantity - 任务数量
 * @property {number|null} produced_quantity - 已生成数量
 * @property {string} earliest - 最早日期 YYYY-MM-DD
 * @property {string} latest - 最晚日期 YYYY-MM-DD
 * @property {string} shipment_date - 交货日期 YYYY-MM-DD
 * @property {'是'|'否'|1|0} is_finished - 是否完成('是'或'否' 1 0)
 * @property {string} process_name - 工序名称
 * @property {string} process_cate_name - 工序类别
 * @property {string} [process_code] - 工序编号
 * @property {string} job_run - 开始时间
 * @property {string} job_end - 完成时间
 * @property {string} remark - 备注信息
 * @property {Array} error - 错误信息列表(当前为空)
 * @property {string} serial_number - 序列号
 * @property {boolean} isIncludedInCalculation - 参与计算
 * @property {string} machine - 使用的机器名称|或者机器代码
 * @property {MachineInfo} machine_info - 机器信息
 */
const ddFmt = 'YYYY-MM-DD';
const dmFmt = 'YYYY-MM-DD HH:mm';
const hmFmt = 'HH:mm';
// 参考exceljs中的ValueType
/**
 * @enum {number}
 */
const cTyp = {
    Null: 0,
    Merge: 1,
    Number: 2,
    String: 3,
    Date: 4,
    Hyperlink: 5,
    Formula: 6,
    SharedString: 7,
    RichText: 8,
    Boolean: 9,
    Error: 10,
    Enum: 11,
    Array: 12,
    Object: 13,
};

/**
 * @Model
 */
const OrdersModel = {
    sheetDefined: {
        name: '订单列表',
        range: {
            'top': 3,
            'left': 3,
            get right() {
                return this.left + OrdersModel.sheetDefined.columns.length - 1;
            },
            'bottom': 34,
        },
        columns: [
            {header: '订单号', key: 'order_code', type: cTyp.String, width: 15},
            {header: '产品', key: 'product', type: cTyp.String, width: 30},
            {header: '数量', key: 'quantity', type: cTyp.Number, width: 10},
            {header: '最早开始日期', key: 'earliest', type: cTyp.Date, dateFmt: ddFmt, width: 20},
            {header: '最迟完成日期', key: 'latest', type: cTyp.Date, dateFmt: ddFmt, width: 20},
            {header: '交货期', key: 'shipment_date', type: cTyp.Date, dateFmt: ddFmt, width: 20},
            {header: '是否完成', key: 'is_finished', type: cTyp.Enum, selctions: ['是', '否'], width: 8},
            {header: '备注', key: 'remark', type: cTyp.String, width: 25},
        ],
    },
    dataDefined: {
        name: 'order-list',
        columns: [
            {key: 'order_code', type: cTyp.String},
            {key: 'task_id', type: cTyp.String},
            {key: 'product', type: cTyp.String},
            {key: 'product_info', type: cTyp.Object},
            {key: 'quantity', type: cTyp.Number},
            {header: '最早开始日期', key: 'earliest', type: cTyp.Date, dateFmt: ddFmt},
            {header: '最迟完成日期', key: 'latest', type: cTyp.Date, dateFmt: ddFmt},
            {header: '交货期', key: 'shipment_date', type: cTyp.Date, dateFmt: ddFmt},
            {header: '是否完成', key: 'is_finished', type: cTyp.Enum, selctions: ['是', '否']},
            {key: 'remark', type: cTyp.String},
            {key: 'error', type: cTyp.Array},
            {key: 'serial_number', type: cTyp.String},
        ],
    },
};
/**
 * @Model
 */
const JobsModel = {
    sheetDefined: {
        name: '任务列表',
        range: {
            'top': 3,
            'left': 3,
            get right() {
                return this.left + JobsModel.sheetDefined.columns.length - 1;
            },
            'bottom': 130,
        },
        columns: [
            {header: '订单号', key: 'order_code', type: cTyp.String, width: 15},
            {header: '任务号', key: 'job_code', type: cTyp.String, width: 15},
            {header: '产品', key: 'product', type: cTyp.String, width: 30},
            {header: '数量', key: 'quantity', type: cTyp.Number, width: 10},
            {header: '完成量', key: 'produced_quantity', type: cTyp.Number, width: 10},
            {header: '工序名称', key: 'process_name', type: cTyp.String, width: 10},
            {header: '是否完成', key: 'is_finished', type: cTyp.Enum, selctions: ['是', '否'], width: 8},
            {header: '最早开始日期', key: 'earliest', type: cTyp.Date, dateFmt: ddFmt, width: 12},
            {header: '最迟完成日期', key: 'latest', type: cTyp.Date, dateFmt: ddFmt, width: 12},
            {header: '已排产机台', key: 'machine', type: cTyp.String, width: 8}, // 机台定义名称或者编号
            {header: '开始时间', key: 'job_run', type: cTyp.Date, dateFmt: dmFmt, width: 17},
            {header: '完成时间', key: 'job_end', type: cTyp.Date, dateFmt: dmFmt, width: 17},
            {header: '备注', key: 'remark', type: cTyp.String, width: 15},
        ],
    },
    dataDefined: {
        name: 'job-list',
        columns: [
            {key: 'order_code', type: cTyp.String},
            {key: 'job_code', type: cTyp.String},
            {key: 'task_id', type: cTyp.String},
            {key: 'isIncludedInCalculation', type: cTyp.Boolean, default: false},
            {key: 'checked', type: cTyp.Boolean, default: false},
            {key: 'product_info', type: cTyp.Object},
            {key: 'product', type: cTyp.String},
            {key: 'process_name', type: cTyp.String},
            {key: 'process_cate_name', type: cTyp.String},
            {key: 'quantity', type: cTyp.Number},
            {key: 'produced_quantity', type: cTyp.Number},
            {header: '最早开始日期', key: 'earliest', type: cTyp.Date, dateFmt: ddFmt},
            {header: '最迟完成日期', key: 'latest', type: cTyp.Date, dateFmt: ddFmt},
            {key: 'shipment_date', type: cTyp.Date},
            {key: 'is_finished', type: cTyp.Enum},
            {key: 'remark', type: cTyp.String},
            {key: 'machine', type: cTyp.String},
            {key: 'machine_info', type: cTyp.Object},
            {header: '开始时间', key: 'job_run', type: cTyp.Date, dateFmt: dmFmt},
            {header: '完成时间', key: 'job_end', type: cTyp.Date, dateFmt: dmFmt},
            {key: 'process_code', type: cTyp.String},
            {key: 'error', type: cTyp.Array},
            {key: 'serial_number', type: cTyp.String},
        ],
    },
};
/**
 * @Model
 */
const JobLocksModel = {
    sheetDefined: {
        name: '任务锁定',
        range: {
            'top': 3,
            'left': 3,
            get right() {
                return this.left + JobLocksModel.sheetDefined.columns.length - 1;
            },
            'bottom': 106,
        },
        columns: [
            {header: '机台', key: 'machine', type: cTyp.String, width: 8},
            {header: '产品', key: 'product', type: cTyp.String, width: 30},
            {header: '任务号', key: 'job_code', type: cTyp.String, width: 15},
            {header: '工序', key: 'process_name', type: cTyp.String, width: 8},
            {header: '准备开始', key: 'pre', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '开始时间', key: 'run', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '完成时间', key: 'end', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '日期', key: 'date', type: cTyp.Date, dateFmt: ddFmt, width: 12},
            {header: '备注', key: 'remark', type: cTyp.String, width: 15},
        ],
    },
    dataDefined: {
        name: 'job-lock-list',
        columns: [
            {key: 'job_code', type: cTyp.String},
            {key: 'product', type: cTyp.String},
            {key: 'product_info', type: cTyp.Object},
            {key: 'remark', type: cTyp.String},
            {key: 'machine', type: cTyp.String},
            {key: 'machine_info', type: cTyp.Object},
            {key: 'pre', type: cTyp.Date, dateFmt: hmFmt},
            {key: 'run', type: cTyp.Date, dateFmt: hmFmt},
            {key: 'end', type: cTyp.Date, dateFmt: hmFmt},
            {header: '日期', key: 'date', type: cTyp.Date, dateFmt: ddFmt},
            {key: 'process_name', type: cTyp.String},
            {key: 'process_cate_name', type: cTyp.String},
            {key: 'error', type: cTyp.Array},
            {key: 'serial_number', type: cTyp.String},
        ],
    },
};
/**
 * @Model
 */
const SchdModel = {
    sheetDefined: {
        name: '排产结果',
        range: {
            'top': 3,
            'left': 3,
            get right() {
                return this.left + SchdModel.sheetDefined.columns.length - 1;
            },
            'bottom': 300,
        },
        columns: [
            {header: '机台', key: 'machine', type: cTyp.String, width: 8, filter: true},
            {header: '任务号', key: 'job_code', type: cTyp.String, width: 15},
            {header: '产品', key: 'product', type: cTyp.String, width: 30},
            {header: '工序', key: 'process_name', type: cTyp.String, width: 8},
            {header: '数量', key: 'quantity', type: cTyp.Number, width: 8},
            {header: '准备开始', key: 'pre', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '开始时间', key: 'run', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '完成时间', key: 'end', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '日期', key: 'date', type: cTyp.Date, dateFmt: ddFmt, width: 14, filter: true},
            {header: '备注', key: 'remark', type: cTyp.String, width: 15},
        ],
    },
    dataDefined: {
        name: 'schd-list',
        columns: [
            {key: 'job_code', type: cTyp.String},
            {key: 'quantity', type: cTyp.Number},
            {key: 'product', type: cTyp.String},
            {key: 'remark', type: cTyp.String},
            {key: 'product_info', type: cTyp.Object},
            {key: 'machine', type: cTyp.String},
            {key: 'machine_info', type: cTyp.Object},
            {key: 'pre', type: cTyp.Date, dateFmt: hmFmt},
            {key: 'run', type: cTyp.Date, dateFmt: hmFmt},
            {key: 'end', type: cTyp.Date, dateFmt: hmFmt},
            {header: '日期', key: 'date', type: cTyp.Date, dateFmt: ddFmt},
            {key: 'process_name', type: cTyp.String},
            {key: 'process_cate_name', type: cTyp.String},
            {key: 'error', type: cTyp.Array},
            {key: 'serial_number', type: cTyp.String},
        ],
    },
};
/**
 * @Model
 */
const EsModel = {
    sheetDefined: {
        name: '预估排产结果',
        range: {
            'top': 3,
            'left': 3,
            get right() {
                return this.left + SchdModel.sheetDefined.columns.length - 1;
            },
            'bottom': 1000,
        },
        columns: [
            {header: '机台', key: 'machine', type: cTyp.String, width: 15, filter: true},
            {header: '任务号', key: 'job_code', type: cTyp.String, width: 20},
            {header: '产品', key: 'product', type: cTyp.String, width: 30},
            {header: '工序', key: 'process_name', type: cTyp.String, width: 10},
            {header: '数量', key: 'quantity', type: cTyp.Number, width: 10},
            {header: '准备开始', key: 'pre', type: cTyp.Date, dateFmt: hmFmt, width: 20},
            {header: '开始时间', key: 'run', type: cTyp.Date, dateFmt: hmFmt, width: 20},
            {header: '完成时间', key: 'end', type: cTyp.Date, dateFmt: hmFmt, width: 20},
            {header: '日期', key: 'date', type: cTyp.Date, dateFmt: ddFmt, width: 15, filter: true},
            {header: '备注', key: 'remark', type: cTyp.String, width: 30},
        ],
    },
    dataDefined: {
        name: 'schd-list',
        columns: [
            {key: 'job_code', type: cTyp.String},
            {key: 'quantity', type: cTyp.Number},
            {key: 'product', type: cTyp.String},
            {key: 'remark', type: cTyp.String},
            {key: 'product_info', type: cTyp.Object},
            {key: 'machine', type: cTyp.String},
            {key: 'machine_info', type: cTyp.Object},
            {key: 'pre', type: cTyp.Date, dateFmt: hmFmt},
            {key: 'run', type: cTyp.Date, dateFmt: hmFmt},
            {key: 'end', type: cTyp.Date, dateFmt: hmFmt},
            {header: '日期', key: 'date', type: cTyp.Date, dateFmt: ddFmt},
            {key: 'process_name', type: cTyp.String},
            {key: 'process_cate_name', type: cTyp.String},
            {key: 'error', type: cTyp.Array},
            {key: 'serial_number', type: cTyp.String},
        ],
    },
};
const SchdMachinesModel = {
    'print': new Map(),
    'complex': new Map(),
    range: {
        'top': 3,
        'left': 3,
    },
};
function xlSerialToJsDate(xlSerial) {
    // exceldate类型转换为utc日期
    return dayjs(Date.UTC(0, 0, xlSerial - 1));
}
function xlSerialToTime(xlSerial) {
    // excel date time类型转换为dayjs
    const MS_PER_DAY = 24 * 60 * 60 * 1000;

    // 将Excel的时间（分数）转换为毫秒
    const millisecondsSinceMidnight = xlSerial * MS_PER_DAY;

    // 创建一个JavaScript的Date对象来表示当天的起始时间（通常是午夜）
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0); // 将时间设置为午夜

    // 将毫秒数添加到午夜时间上，得到正确的时间

    return dayjs(midnight.getTime() + millisecondsSinceMidnight);
}

class AutoSchTabluar {
    constructor() {
        this.orderModel = OrdersModel;
        this.jobModel = JobsModel;
        this.jobLockModel = JobLocksModel;
        this.schdModel = SchdModel;
        this.schdSheet = SchdMachinesModel;
        this.idCounter = 0;
    }

    dateFmt(model, colkey) {
        let _fmt = '';
        this[model].dataDefined.columns.forEach((col) => {
            if (col.key === colkey) {
                _fmt = col.dateFmt;
            }
        });
        return _fmt;
    }

    get requireModels() {
        return [this.orderModel, this.jobModel, this.jobLockModel];
    }

    get requireSheetsNames() {
        return this.requireModels.map((model) => model.sheetDefined.name);
    }

    getSheetHead(sheet, range) {
        const _headers = [];
        sheet.getRow(range.top).eachCell((cell) => {
            _headers.push(cell.value);
        });
        return _headers;
    }

    // 静态方法
    static getInstance() {
        if (!this.instance) {
            this.instance = new AutoSchTabluar();
        }
        return this.instance;
    }

    decodeAddr(sheet, {top, bottom, left, right}) {
        const startCell = sheet.getCell(top, left).address;
        const endCell = sheet.getCell(bottom, right).address;
        return `${startCell}:${endCell}`;
    }

    /**
     *
     * @param {Workbook} workbook
     * @returns
     */
    validWorkBook(workbook) {
        // 判断传入的文件是否是excel文档
        if (!workbook) {
            ElMessage({showClose: true, message: 'Excel文档不能为空', type: 'error'});
            throw new Error('Excel文档不能为空');
        }
        // 名称校验
        if (!this.validateSheetNames(workbook.worksheets.map((sheet) => sheet.name))) {
            ElMessage({
                showClose: true,
                message: `Excel文档中必须包含:${this.requireSheetsNames.join(',')} 这三个sheet`,
                type: 'error',
            });
            throw new Error(`Excel文档中必须包含:${this.requireSheetsNames.join(',')} 这三个sheet`);
        }
        // sheet的数据范围校验
        this.requireModels.forEach((model) => {
            const res = this.validateSheetRange(workbook, model.sheetDefined.name, model.sheetDefined.range);
            if (!res) {
                ElMessage({
                    showClose: true,
                    message: `Sheet${model.sheetDefined.name} 
                    的数据范围校验失败,其输入范围${this.decodeAddr(workbook.getWorksheet(model.sheetDefined.name),
        model.sheetDefined.range)}`,
                    type: 'error',
                });
                throw new Error(`Sheet${model.sheetDefined.name} 的数据范围校验失败,其输入范围
                ${this.decodeAddr(workbook.getWorksheet(model.sheetDefined.name),
        model.sheetDefined.range)}`);
            }
        });
        // 必要的sheet的表格头部校验
        this.requireModels.forEach((model) => {
            const res = this.validateSheetHeader(workbook, model.sheetDefined);
            if (!res) {
                ElMessage({
                    showClose: true,
                    message: `Sheet${model.sheetDefined.name} 的表头校验失败`,
                    type: 'error',
                });
                throw new Error(`Sheet${model.sheetDefined.name} 的表头校验失败`);
            }
        });
        // 排产计算结果sheet验证,如果有sheet则进行校验否则不需要
        const schdsheet = workbook.getWorksheet(this.schdModel.sheetDefined.name);
        if (schdsheet) {
            // 校验表头信息
            const res = this.validateSheetHeader(workbook, this.schdModel.sheetDefined);
            if (!res) {
                ElMessage({
                    showClose: true,
                    message: `Sheet${this.schdModel.sheetDefined.name} 的表头校验失败`,
                    type: 'error',
                });
                throw new Error(`Sheet${this.schdModel.sheetDefined.name} 的表头校验失败`);
            }
        }
        return true;
    }

    validateSheetNames(names) {
        if (!names || names.length === 0) {
            return false;
        }

        for (const name of names) {
            if (typeof name !== 'string' || name.trim() === '') {
                return false;
            }
        }

        // const validNames = ['订单列表', '任务列表', '任务锁定']
        return this.requireSheetsNames.every((name) => names.includes(name));
    }

    /**
     *
     * @param {Workbook} workbook
     * @param {object} model
     * @returns {Boolean}
     */
    validateSheetHeader(workbook, model) {
        const sheetHeaders = this.getSheetHead(workbook.getWorksheet(model.name), model.range);
        if (sheetHeaders.length === 0) {
            ElMessage({
                showClose: true,
                message: `${model.name}表头信息不完整,请在第${model.range.top}行填写表头`,
                type: 'error',
            });
            return false;
        }
        // headers与sheetHeader的值一一对应
        for (let i = 0; i < model.columns.length; i++) {
            if (sheetHeaders[i] !== model.columns[i].header) {
                ElMessage(
                    {
                        showClose: true,
                        message: `${model.name}中的表头第 ${i + model.range.left}列
                    期望值:"${model.columns[i].header}"，实际值:"${sheetHeaders[i]}".`,
                        type: 'error',
                    });
                return false;
            }
        }
        // const errMsg = [];
        return true;
    }

    /**
     *
     * @param {Workbook} workbook
     * @param {String} sheetname
     * @param {Object} range
     * @returns {Boolean}
     */
    validateSheetRange(workbook, sheetname, range) {
        if (workbook.getWorksheet(sheetname).dimensions.left > range.right) {
            ElMessage({
                showClose: true,
                message: `${sheetname}sheet的列数超过${range.right}列`,
                type: 'error',
            });
            return false;
        }
        if (workbook.getWorksheet(sheetname).dimensions.bottom > range.bottom) {
            ElMessage({
                showClose: true,
                message: `${sheetname}sheet的行数超过${range.bottom - range.top - 1}行`,
                type: 'error',
            });
            return false;
        }
        return true;
    }
    /**
     *
     * @returns
     */

    getRemoteData() {
        return [];
    }

    /**
     *
     * @param {Workbook} workbook
     * @returns {Object}
     */
    getWorkBookData(workbook) {
        // 读取数据
        return {
            'orderList': this.getSheetData(workbook, this.orderModel),
            'jobList': this.getSheetData(workbook, this.jobModel),
            'jobLockList': this.getSheetData(workbook, this.jobLockModel),
            'schdList': this.getSheetData(workbook, this.schdModel),
        };
    }

    /**
     *
     * @param {Workbook} workbook
     * @param {Model} model
     * @returns {Array<Object>}
     */
    getSheetData(workbook, model) {
        const {
            sheetDefined: {range, columns: _headers, name},
            dataDefined: {columns: dataColumns, name: dataName},
        } = model;
        const sheet = workbook.getWorksheet(name);
        const modelDataRows = [];
        if (!sheet) {
            return modelDataRows;
        }
        let _top = range.top + 1;
        while (sheet.getRow(_top).hasValues
        && sheet.getRow(_top).actualCellCount > 0 && _top <= range.bottom) {
            // const _row = sheet.getRow(_top);
            // if (_row._number === 11 && model.sheetDefined.name === '任务列表') {
            //     _row._cells.forEach(cell => {
            //         console.log(cell, cell.type, cTyp.Null, cell && cell.type !== cTyp.Null);
            //     });
            // }
            const modelData = colsToObj(dataColumns);
            modelData.serial_number = this.uniqueId(dataName);
            for (let i = range.left; i < range.right; i++) {
                const _h = _headers[i - range.left];
                const _cell = sheet.getRow(_top).getCell(i);
                const _v = this.readCellData(_cell, _h);
                modelData[_h.key] = _v;
            }
            modelDataRows.push(modelData);
            _top++;
        }
        return modelDataRows;
    }

    calcSerial(sheetData) {
        return sheetData.reduce((acc, cur) => {
            acc = cur.serial_number > acc ? cur.serial_number : acc;
            return acc;
        }, 0) + 1;
    }

    /**
     *
     * @param {import('exceljs').Cell} cell
     * @param {Object} colDefined
     */
    readCellData(cell, colDefined) {
        // 处理是否完成
        if (colDefined.key === 'is_finished') {
            // 如何cell.value 值为是或者1 则返回是否则返回否
            if (cell.value === '是' || cell.value === 1) {
                return '是';
            } else {
                return '否';
            }
        }
        // 处理excel中的日期类型
        // 1key中定义的为日期
        if (colDefined.type === cTyp.Date) {
            return this.parseExcelToJSDate(cell, colDefined);
        }
        if (colDefined.type === cTyp.String) {
            if (typeof cell.value === 'string') {
                return cell.value.trim();
            }
            return cell.value;
        }
        return cell.value;
    }

    /**
     *
     * @param {import('exceljs').Cell} cell
     * @param {Object} colDefined
     */
    parseExcelToJSDate(cell, colDefined) {
        // 1 cell.type ValueType,如果为数字类型其dateFmt为hmFmt
        if (cell.type === excelValueType.Number) {
            const _dmt = colDefined.dateFmt === hmFmt
                ? xlSerialToTime(cell.value)
                : xlSerialToJsDate(cell.value);

            if (_dmt.isValid()) {
                return _dmt.format(colDefined.dateFmt);
            }
            return cell.value;
        }
        if (cell.type === excelValueType.Date) {
            if (colDefined.dateFmt === hmFmt) {
                // 处理时间相关的内容
                return dayjs().hour(cell.value.getUTCHours())
                    .minute(cell.value.getUTCMinutes()).format(hmFmt);
            }
        }
        if (dayjs(cell.value).isValid()) {
            return dayjs(cell.value).format(colDefined.dateFmt);
        }
        return cell.value;
    }

    mkJobTpl() {
        return colsToObj(this.jobModel.dataDefined.columns);
    }

    mkOrderTpl() {
        return colsToObj(this.orderModel.dataDefined.columns);
    }

    mkLockTpl() {
        return colsToObj(this.jobLockModel.dataDefined.columns);
    }

    mkSchdTpl() {
        return colsToObj(this.schdModel.dataDefined.columns);
    }

    /**
     *
     * @param {Object} data
     * @param {Model} model
     */
    doFmtData(data, model) {
        // 返回相应tpl以及model数据,符合model中的日期格式或者时间格式的数据定义
        model.dataDefined.columns.forEach(col => {
            if (col.type === cTyp.Date) {
                const _vd = dayjs(data[col.key]);
                if (_vd.isValid()) {
                    data[col.key] = _vd.format(col.dateFmt);
                }
            }
            if (col.type === cTyp.Enum && col.key === 'is_finished') {
                // data[col.key] == 1 or '1' or '是'
                if (data[col.key] === 1 || data[col.key] === '1' || data[col.key] === '是') {
                    data[col.key] = '是';
                } else {
                    data[col.key] = '否';
                }
            }
        });
    }

    /**
     *
     * @param {Object} data
     */
    doOrderFmtData(data) {
        this.doFmtData(data, this.orderModel);
    }

    /**
     *
     * @param {Object} data
     */
    doJobFmtData(data) {
        this.doFmtData(data, this.jobModel);
    }

    /**
     *
     * @param {Object} data
     */
    doLockFmtData(data) {
        this.doFmtData(data, this.jobLockModel);
    }

    /**
     *
     * @param {Object} data
     */
    doSchdFmtData(data) {
        this.doFmtData(data, this.schdModel);
    }

    async parseExcelFile(file) {
        const workbook = new Workbook();
        await workbook.xlsx.load(file.raw);
        const res = this.validWorkBook(workbook);
        if (!res) {
            ElMessage({showClose: true, message: 'Excel文档格式不正确,请重选择Excel文档', type: 'error'});
            throw new Error('Excel文档格式不正确,请重选择Excel文档');
        }
        // 解析数据
        return this.getWorkBookData(workbook);
        // return workbook;
    }

    /**
     *
     * @param {String} [prefix]
     * @returns {String}
     */
    uniqueId(prefix) {
        const id = ++this.idCounter;
        const _now = Date.now();
        if (prefix) {
            return `${prefix}-${_now}-${id}`;
        }
        return `${_now}-${id}`;
    }

    resetIDCounter() {
        this.idCounter = 0;
    }
}
const ErrorTags = {
    'job-prd-not-equel-order': {
        tag: 'job-prd-not-equel-order',
        info: '产品与订单不匹配',
    },
    'product-not-found': {
        tag: 'product-not-found',
        info: '产品信息没有创建,请先创建相应的产品',
    },
    'product-not-cfg': {
        tag: 'product-not-cfg',
        info: '产品信息没有配置,请先配置相应的产品',
    },
    'order-code-repeat': {
        tag: 'order-code-repeat',
        info: '订单编号重复!',
    },
    'order-code-not-found': {
        tag: 'order-code-not-found',
        info: '没有找到相应的订单号',
    },
    'job-process-not-found': {
        tag: 'job-process-not-found',
        info: '没有找到相应的工序,请选择正确的工序名称',
    },
    'order-date-require': {
        tag: 'order-date-require',
        info: '最早开始日期,最迟完成日期,交货期不能为空!',
    },
    'job-date-require': {
        tag: 'order-date-require',
        info: '最早开始日期,最迟完成日期不能为空!',
    },
    'earliest-format': {
        tag: 'earliest-format',
        info: '最早开始日期格式错误!',
    },
    'latest-format': {
        tag: 'latest-format',
        info: '最迟完成日期格式错误!',
    },
    'shipment-format': {
        tag: 'shipment-format',
        info: '交货期格式错误!',
    },
    'earliest-after-latest': {
        tag: 'earliest-after-latest',
        info: '最早交货期不能晚于最新交货期!',
    },
    'latest-after-shipment': {
        tag: 'earliest-after-latest',
        info: '最迟完成日期不能早于发货日期!',
    },
    'mac-not-found': {
        tag: 'mac-not-found',
        info: '机台不存!',
    },
    'job-not-found': {
        tag: 'job-not-found',
        info: '任务单号不存在!',
    },
    'latest-before-plan': {
        tag: 'latest-before-plan',
        info: '最迟完成日期不在计算日期范围之内!',
    },
};

const templateWorkbook = () => {
    const workbook = new Workbook();
    initSheet(workbook, OrdersModel);
    initSheet(workbook, JobsModel);
    initSheet(workbook, JobLocksModel);
    initSheet(workbook, SchdModel);
    return workbook;
};
/**
 *
 * @param {Workbook} workbook
 * @param {Model} model
 */
const initSheet = (workbook, model) => {
    /**
     * @type {import('exceljs').Fill}
     */
    const _hFill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '91AADF'},
    };
    /**
     * @type {import('exceljs').Border}
     */
    const _hStyle = {style: 'thin', color: {argb: 'FF000000'}};
    const {name, range: {left, top}, columns} = model.sheetDefined;
    const sheet = workbook.addWorksheet(name);
    // sheet.autoFilter = 'C2:J2';
    sheet.views = [{state: 'frozen', xSplit: left, ySplit: top}];
    // 设置列宽,列label,列key
    sheet.columns = [...Array(left - 1).fill({header: null, key: null, width: 4}),
        ...columns.map(({key, width = 10, header}) => {
            return {header, key, width};
        }),
    ];
    for (let i = 1; i < top; i++) {
        sheet.insertRow(i);
    }
    const _header = sheet.getRow(top);
    _header.eachCell((cell) => {
        cell.border = {
            top: _hStyle,
            left: _hStyle,
            bottom: _hStyle,
            right: _hStyle,
        };
        cell.fill = _hFill;
    });
    _header.commit();
};

/**
 * 按照机台和日期进行分组的排产信息
 * @typedef {Object} schdByMacDate
 * @property {string} machine_name - 机台名称
 * @property {string} machine_code - 机台编号
 * @property {string} sheet_name - excel的sheet名称
 * @property {string} cate_code - 工序code
 * @property {string[]} dates - 日期列表
 * @property {Array<ScheduleResult[]>} data - 数据
 */
/**
* @param {ScheduleResult[]} items 排产信息
* @param {Object[]} macs 机台信息
* @return {schdByMacDate[]} 转换的排产数据
*/
const mkMacSchdData = (items, macs) => {
    // 对items进行分组
    // 按照机台进行分组
    const transformedData = {};

    items.forEach(item => {
        const {machine, date} = item;

        // 创建 machine 对应的属性
        if (!transformedData[machine]) {
            transformedData[machine] = {};
        }

        // 创建 date 对应的属性
        if (!transformedData[machine][date]) {
            transformedData[machine][date] = [];
        }
        // 添加数据到对应的 machine 和 date 中
        transformedData[machine][date].push(item);
    });
    // 对 transformedData pre进行排序处理
    Object.keys(transformedData).forEach(machine => {
        Object.keys(transformedData[machine]).forEach(date => {
            transformedData[machine][date].sort((a, b) => {
                const preA = dayjs(`${date} ${a.pre}`, 'YYYY-MM-DD HH:mm');
                const preB = dayjs(`${date} ${b.pre}`, 'YYYY-MM-DD HH:mm');
                return preA.isBefore(preB) ? -1 : 1;
            });
        });
    });
    // 对macs进行排序,按照印刷->复合->id进行排序
    const categoryOrder = {
        'print': 1,
        'complex': 2,
    };
    macs.sort((a, b) => {
        const _sA = categoryOrder[a.category.code] || 0;
        const _sB = categoryOrder[b.category.code] || 0;
        if (_sA === _sB) {
            return a.id - b.id;
        }
        return _sA - _sB;
    });
    // 通过macs与tranformedData进行合并
    const res = [];
    macs.forEach(mac => {
        const {name: machine_name, code: machine_code, category: {name: cate_name, code: cate_code}} = mac;
        let _tranData = {};
        if (Object.hasOwn(transformedData, machine_name)) {
            _tranData = {...transformedData[machine_name]};
        } else if (Object.hasOwn(transformedData, machine_code)) {
            _tranData = {...transformedData[machine_code]};
        }
        if (Object.keys(_tranData).length > 0) {
            const _keys = Object.keys(_tranData);
            _keys.sort((a, b) => {
                return dayjs(a, 'YYYY-MM-DD').isBefore(dayjs(b, 'YYYY-MM-DD')) ? -1 : 1;
            });
            res.push({
                machine_name,
                machine_code,
                'sheet_name': `${cate_name}-${machine_code}`,
                'dates': _keys,
                'data': _keys.map((date) => {
                    return _tranData[date];
                }),
                cate_code,
            });
        }
    });
    return res;
};
/**
 *
 * @param {Workbook} workbook
 * @param {Array} data
 * @param {Object} model
 * @param {Object} [tabColor]
 */
const writeToSheet = (workbook, data, model, tabColor) => {
    /**
     * @type {import('exceljs').Border}
     */
    const _hStyle = {style: 'thin', color: {argb: 'FF000000'}};
    const {name, range: {top, left, right}} = model.sheetDefined;
    if (!workbook.getWorksheet(name)) {
        initSheet(workbook, model);
    }
    const sheet = workbook.getWorksheet(name);
    if (tabColor) {
        sheet.properties.tabColor = tabColor;
    }
    let cIndex = top + 1;
    let dataLength = data.length;
    while (dataLength > 0) {
        dataLength -= 1;
        const _crow = sheet.addRow({...data[cIndex - top - 1]});
        for (let i = left; i <= right; i++) {
            sheet.getCell(cIndex, i).border = {
                top: _hStyle,
                left: _hStyle,
                bottom: _hStyle,
                right: _hStyle,
            };
        }

        _crow.commit();
        cIndex += 1;
    }
};

/**
 * 将排产数据写入excel中,按照工序+机台sheet,sheet中按照日期划分
 * @param {Workbook} workbook - excelbook
 * @param {ScheduleResult[]} items - 精计算排产数据
 * @param {Object[]} macs - 机台信息
 */
const writeToMacSchdSheet = (workbook, items, macs) => {
    const data = mkMacSchdData(items, macs);
    // 处理print shcd data
    tranToSchdData(data);
    const printHeader = [
        // 设置sheet的样式以及列宽等信息
        [
            {header: '日期', key: 'date', type: cTyp.Date, dateFmt: ddFmt, width: 8},
            {header: '机台', key: 'machine', type: cTyp.String, width: 8},
        ],
        [
            {header: '序号', key: '_index', type: cTyp.Number, width: 4},
            {header: '任务号', key: 'job_code', type: cTyp.String, width: 15},
            {header: '产品', key: 'product', type: cTyp.String, width: 30},
            {header: '工序', key: 'process_name', type: cTyp.String, width: 8},
            {header: '结构', key: 'p_struct', type: cTyp.String, width: 8},
            {header: '数量', key: 'quantity', type: cTyp.Number, width: 8},
            {header: '准备', key: 'pre', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '开始', key: 'run', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '完成', key: 'end', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '幅宽', key: '幅宽', type: cTyp.String, width: 8},
            {header: '光油', key: '光油', type: cTyp.String, width: 8},
            {header: '色序', key: '色序', type: cTyp.String, width: 8},
            {header: '印面', key: '印面', type: cTyp.String, width: 8},
            {header: '材质', key: '材质', type: cTyp.String, width: 8},
            {header: '备注', key: 'remark', type: cTyp.String, width: 15},
        ],
    ];
    const complexHeader = [
        // 设置sheet的样式以及列宽等信息
        [
            {header: '日期', key: 'date', type: cTyp.Date, dateFmt: ddFmt, width: 8},
            {header: '机台', key: 'machine', type: cTyp.String, width: 8},
        ],
        [
            {header: '序号', key: '_index', type: cTyp.Number, width: 4},
            {header: '任务号', key: 'job_code', type: cTyp.String, width: 15},
            {header: '产品', key: 'product', type: cTyp.String, width: 30},
            {header: '工序', key: 'process_name', type: cTyp.String, width: 8},
            {header: '结构', key: 'p_struct', type: cTyp.String, width: 8},
            {header: '数量', key: 'quantity', type: cTyp.Number, width: 8},
            {header: '准备', key: 'pre', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '开始', key: 'run', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '完成', key: 'end', type: cTyp.Date, dateFmt: hmFmt, width: 8},
            {header: '复次', key: '复次', type: cTyp.String, width: 8},
            {header: '类型', key: '类型', type: cTyp.String, width: 8},
            {header: '胶水', key: '胶水', type: cTyp.String, width: 8},
            {header: '备注', key: 'remark', type: cTyp.String, width: 15},
        ],
    ];
    writeSchdSheet(workbook, data.filter(item => item.cate_code === 'print'), printHeader);
    writeSchdSheet(workbook, data.filter(item => item.cate_code === 'complex'), complexHeader);
};

/**
 * 写入预估排产信息
 * @param {Workbook} workbook
 * @param {ScheduleResult[]} esSchList
 * @param {Model} model
 */
const writeToEschdSheets = (workbook, esSchList, model) => {
    writeToSheet(workbook, esSchList, model);
    workbook.getWorksheet(model.sheetDefined.name).properties.tabColor = {argb: 'FFCCCC'};
    // 处理各个工序的排产数据并写入相应的sheet中
    /** @type {Map<string, Map<string, ScheduleResult[]>>} */
    const gCateMap = esSchList.reduce((res, cur) => {
        const mKey = `${cur.process_cate_name}${cur.machine ? `-${cur.machine}` : ''}`;
        if (!res.has(mKey)) {
            res.set(mKey, new Map());
        }
        if (!res.get(mKey).has(cur.date)) {
            res.get(mKey).set(cur.date, []);
        }
        res.get(mKey).get(cur.date).push(cur);
        return res;
    }, new Map());
    // 再次对gCateMap中的数据进行分组排序按照pre的时间进行排序
    gCateMap.forEach((sheet) => {
        sheet.forEach((dataList) => {
            dataList.sort((a, b) => {
                return dayjs(a.pre).isBefore(dayjs(b.pre)) ? 1 : 0;
            });
        });
    });
    const _header = [
        // 设置sheet的样式以及列宽等信息
        [
            {header: '日期', key: 'date', type: cTyp.Date, dateFmt: ddFmt, width: 8},
            {header: '机台', key: 'machine', type: cTyp.String, width: 8},
        ],
        [
            {header: '任务号', key: 'job_code', type: cTyp.String, width: 15},
            {header: '产品', key: 'product', type: cTyp.String, width: 30},
            {header: '工序', key: 'process_name', type: cTyp.String, width: 12},
            {header: '数量', key: 'quantity', type: cTyp.Number, width: 8},
            {header: '准备', key: 'pre', type: cTyp.Date, dateFmt: hmFmt, width: 18},
            {header: '开始', key: 'run', type: cTyp.Date, dateFmt: hmFmt, width: 18},
            {header: '完成', key: 'end', type: cTyp.Date, dateFmt: hmFmt, width: 18},
            {header: '备注', key: 'remark', type: cTyp.String, width: 30},
        ],
    ];
    const sheets = Array.from(gCateMap.keys().map((sheetName) => {
        const dateKeys = Array.from(gCateMap.get(sheetName).keys());
        dateKeys.sort((a, b) => {
            return dayjs(a).isBefore(dayjs(b)) ? 1 : 0;
        });
        /** @type {schdByMacDate} */
        const sch = {};
        sch.dates = dateKeys;
        sch.sheet_name = sheetName;
        sch.machine_code = gCateMap.get(sheetName).get(dateKeys[0])[0].machine;
        sch.machine_name = sch.machine_code;
        sch.data = dateKeys.map((date) => {
            return gCateMap.get(sheetName).get(date);
        });
        return sch;
    }));
    writeSchdSheet(workbook, sheets, _header, {argb: 'FFCCCC'});
};
/**
 *
 * @param {schdByMacDate[]} sheets
 */
const tranToSchdData = (sheets) => {
    sheets.forEach((sheet) => {
        sheet.data.forEach((schList) => {
            schList.forEach((sch, i) => {
                const process = sch.product_info?.craft.find(craft => {
                    return craft.name === sch.process_name;
                });
                sch._index = i + 1;
                sch.p_struct = process?.structure || '';
                sch.幅宽 = process?.幅宽 || '';
                sch.光油 = process?.光油 || '';
                sch.色序 = Array.isArray(process?.色序) ? process?.色序.join(',') : '';
                sch.印面 = process?.印面 || '';
                sch.材质 = process?.材质 || '';
                sch.胶水 = process?.胶水 || '';
                sch.复次 = process?.复次 || '';
                sch.类型 = process?.类型 || '';
            });
        });
    });
};
function copyRow(sourceRow, targetRow) {
    targetRow.values = sourceRow.values;

    sourceRow.eachCell({includeEmpty: true}, (cell, colNumber) => {
        const targetCell = targetRow.getCell(colNumber);

        // 复制样式
        targetCell.style = {...cell.style};

        // 复制其他属性（例如，边框，填充，字体等）
        if (cell.border) targetCell.border = {...cell.border};
        if (cell.fill) targetCell.fill = {...cell.fill};
        if (cell.font) targetCell.font = {...cell.font};
        if (cell.alignment) targetCell.alignment = {...cell.alignment};
        if (cell.numFmt) targetCell.numFmt = cell.numFmt;
    });
}
/**
 *
 * @param {Workbook} workbook
 * @param {schdByMacDate[]} items
 * @param {Array<object>} headers 双行表头
 * @param {Object} tabColor
 */
const writeSchdSheet = (workbook, items, [headerL1, headerL2], tabColor = {argb: 'ADD8E6'}) => {
    const colStart = 3;
    const minSchdCount = 37;
    /**
     * @type {import('exceljs').Fill}
     */
    const _hFill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '91AADF'},
    };
    /**
     * @type {import('exceljs').Border}
     */
    const _hStyle = {style: 'thin', color: {argb: 'FF000000'}};

    items.forEach((item) => {
        const {sheet_name, dates, machine_name} = item;
        const sheet = workbook.addWorksheet(sheet_name);
        sheet.properties.tabColor = tabColor;
        // 设置sheet的页面信息,横向,A4
        sheet.pageSetup.orientation = 'landscape';
        sheet.pageSetup.paperSize = 9;
        sheet.pageSetup.fitToPage = true;
        sheet.pageSetup.fitToWidth = 1;
        // const printAreas = [];
        // 设置列宽以及列名信息
        sheet.columns = [
            ...Array(colStart - 1).fill({header: null, key: null, width: 4}),
            ...headerL2];
        // 插入两行最终保证上面有一个空行
        sheet.insertRow(1);
        sheet.insertRow(1);
        const rowL1 = sheet.getRow(2);
        rowL1.getCell(4).value = headerL1[0].header;
        rowL1.getCell(7).value = headerL1[1].header;
        rowL1.commit();
        const rowL2 = sheet.getRow(3);
        rowL2.eachCell((cell) => {
            cell.border = {
                top: _hStyle,
                left: _hStyle,
                bottom: _hStyle,
                right: _hStyle,
            };
            cell.fill = _hFill;
        });
        rowL2.commit();

        dates.forEach((date, index) => {
            // let _startArea = rowL1.getCell(colStart).address;
            const schdList = item.data[index];
            schdList.forEach((schd, i) => {
                if (index === 0) {
                    rowL1.getCell(5).value = date;
                    rowL1.getCell(8).value = machine_name;
                }
                if (index > 0 && i === 0) {
                    // 复制插入两行数据
                    const _l1 = sheet.addRow();
                    // _startArea = _l1.getCell(colStart).address;
                    copyRow(rowL1, _l1);
                    _l1.getCell(5).value = date;
                    _l1.getCell(8).value = machine_name;
                    const _l2 = sheet.addRow();
                    copyRow(rowL2, _l2);
                };
                // 对item进行处理将宽幅等信息进行赋值
                const row = sheet.addRow();
                headerL2.forEach((col, colindex) => {
                    const _cell = row.getCell(colStart + colindex);
                    _cell.border = {
                        top: _hStyle,
                        left: _hStyle,
                        bottom: _hStyle,
                        right: _hStyle,
                    };
                    _cell.value = schd[col.key];
                });
            });
            // 如果schdList不足最小行数,则补空行
            for (let i = minSchdCount - schdList.length; i > 0; i--) {
                sheet.addRow();
            }
            // 增加2个空行,记录打印区域
            const _endrow = sheet.lastRow;
            // const _endArea = _endrow.getCell(colStart + headerL2.length - 1).address;
            // WPS无法正确识别分页符，打印的时候只能手工调整处理
            _endrow.addPageBreak();
            // printAreas.push(`${_startArea}:${_endArea}`);
        });
        // 设置打印区域,wps无法识别多个打印区域
        // sheet.pageSetup.printArea = printAreas.join('&&');
        // console.log(sheet.name, sheet.pageSetup, sheet.model.rowBreaks);
    });
};

const exportDataToFile = async (store) => {
    const workbook = templateWorkbook();
    // 添加数据信息
    const macs = await store.fetchMachines();
    addDataToWorkbook(workbook, store, macs.data);
    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
};
/**
 *
 * @param {Workbook} workbook
 * @param {Object} store
 * @param {Object[]} macs
 */
const addDataToWorkbook = (workbook, store, macs) => {
    writeToSheet(workbook, store.orderList, OrdersModel);
    writeToSheet(workbook, store.jobList, JobsModel);
    writeToSheet(workbook, store.jobLockList, JobLocksModel);
    writeToSheet(workbook, store.schdList, SchdModel, {argb: 'ADD8E6'});
    writeToMacSchdSheet(workbook, store.schdList, macs);
    if (store.estimateSchdList.length > 0) {
        writeToEschdSheets(workbook, store.estimateSchdList, EsModel);
    }
};
/**
 * 根据列定义数组生成对应的对象
 * @param {Array<column>} columns 列定义数组
 * @return {Object} 生成的对象，键为列的 key，值为默认值（根据类型确定）
 */
function colsToObj(columns) {
    const obj = {};
    columns.forEach(column => {
        const {key, type} = column;
        switch (type) {
        case cTyp.String:
            obj[key] = '';
            break;
        case cTyp.Number:
            obj[key] = null;
            break;
        case cTyp.Date:
            obj[key] = null;
            break;
        case cTyp.Array:
            obj[key] = [];
            break;
        case cTyp.Object:
            obj[key] = null;
            break;
        case cTyp.Boolean:
            obj[key] = column?.default || false;
            break;
        default:
            obj[key] = null;
            break;
        }
    });
    return obj;
}
const autoTabluar = AutoSchTabluar.getInstance();
export {
    autoTabluar, ErrorTags, templateWorkbook, exportDataToFile, OrdersModel,
    JobsModel, SchdModel, colsToObj, JobLocksModel,
};
