<template>
    <el-row class="query-row">
        <el-col :span="6">
            <el-input
                v-model="keyword"
                autocomplete="off"
                placeholder="订单号|产品"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="1" />
        <el-col :span="6" />
        <el-col :span="9" />
        <el-col :span="2" />
    </el-row>
    <el-row class="table-container">
        <el-table
            border
            :data="lockedList"
            row-key="serial_number"
            height="100%"
            :header-cell-style="{background:'#f5f7fa',color:'#606266'}">
            <el-table-column
                align="center"
                width="80"
                label=""
                type="expand">
                <template #default="{row}">
                    <el-tag v-for="(item,i) in row.error" :key="i" type="danger">{{ item.info }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                width="80"
                label="">
                <template #default="{row,$index}">
                    <el-text class="mx-1" :type="row.error.length>0?'danger':''">
                        {{ $index+1 }}
                        <el-icon v-if="row.error.length>0"><WarningFilled /></el-icon>
                    </el-text>
                </template>
            </el-table-column>
            <el-table-column
                v-for="(item) in headers" :key="item.key" :label="item.header">
                <template v-if="item.key==='is_finished'" #default="{ row }">
                    <el-switch
                        v-model="row.is_finished"
                        inline-prompt
                        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                        active-value="否"
                        active-text="否"
                        inactive-text="是"
                        inactive-value="是" />
                </template>
                <template v-else #default="{ row }">
                    {{ row[item.key] }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="" width="auto" align="center">
                <template #default="scope">
                    <el-tooltip content="删除" placement="top">
                        <el-button
                            link
                            type="danger"
                            size="large"
                            @click="deleteItem(scope)">
                            <el-icon><delete /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
    </el-row>
</template>
<script>
import SchdStore from '../stores/schdtabular.js';
import {ElMessageBox} from 'element-plus';
import {notify} from '../assets/js/utils.js';
import {JobLocksModel} from '../assets/js/schd.js';
import {Delete, WarningFilled} from '@element-plus/icons-vue';
export default {
    name: 'LockedJobList',
    components: {
        Delete,
        WarningFilled,
    },
    setup() {
        const uWbStore = SchdStore();
        return {uWbStore};
    },
    data() {
        return {
            keyword: '',
            headers: JobLocksModel.sheetDefined.columns,
        };
    },
    computed: {
        lockedList() {
            const _keyword = this.keyword.trim();
            if (_keyword) {
                return this.uWbStore.jobLockList.filter((item) => {
                    return item.job_code.includes(_keyword) || item?.product?.includes(_keyword);
                });
            }
            return this.uWbStore.jobLockList;
        },
    },
    methods: {
        deleteItem(scope) {
            ElMessageBox.confirm(
                `删除本行数据？${scope.row.job_code}`,
                '提醒',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                },
            )
                .then(() => {
                    this.uWbStore.deleteLocks(scope.row.serial_number);
                    notify('success', '删除成功', 1500);
                })
                .catch((msg) => {
                    notify('info', msg === 'cancel' ? '取消' : msg, 1500);
                });
        },
    },
};
</script>
<style>
/* Add your component styles here */
.query-row {
    height: 50px;
}
.table-container {
    height: calc(100% - 50px);
}
.el-tag +.el-tag {
    margin-left: 10px;
}
</style>
