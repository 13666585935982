import {defineStore} from 'pinia';
import {notify} from '../assets/js/utils.js';
import {stringify} from '../assets/js/json.js';
import {autoTabluar, ErrorTags} from '../assets/js/schd.js';
import axios from 'axios';
import dayjs from 'dayjs';
const schdModelDict = autoTabluar.schdModel.dataDefined.columns.reduce((pre, cur) => {
    pre[cur.key] = cur;
    return pre;
}, {});
/**
 * @import {OrderResult,JobResult,ScheduleResult} from '../assets/js/schd.js';
 */
const SchdStore = defineStore({
    id: 'SchdStore',
    state: () => ({
        /** @type {OrderResult[]} */
        orderList: [],
        /** @type {JobResult[]} */
        jobList: [],
        jobLockList: [],
        /** @type {ScheduleResult[]} */
        schdList: [],
        /** @type {ScheduleResult[]} */
        estimateSchdList: [],
        g_plan_date: null,
        currentTaskId: null,
        taskStart: null,
        taskRunMaxSec: 30,
    }),
    getters: {
        // 订单编号对应的产品信息
        orderCodePrdsDict: (state) => {
            return state.orderList.reduce((acc, item) => {
                if (Object.hasOwn(acc, item.order_code)) {
                    acc[item.order_code].push(item.product_info);
                } else {
                    acc[item.order_code] = [item.product_info];
                }
                return acc;
            }, {});
        },
        // 订单编号统计
        orderCodesCount: (state) => {
            return state.orderList.reduce((obj, item) => {
                if (obj[item.order_code]) {
                    obj[item.order_code]++;
                } else {
                    obj[item.order_code] = 1;
                }
                return obj;
            }, {});
        },
        // 已排产的汇总信息
        schedSummary() {
            const summary = {};
            // job-code,process-name:summary
            this.schdList.forEach((item) => {
                const groupkey = [item.job_code, item.product_info?.id || '', item.process_name].join('-');
                const schdDate = dayjs(item.date, schdModelDict.date.dateFmt);
                const itemPre = dayjs(item.pre, schdModelDict.pre.dateFmt);
                const itemEnd = dayjs(item.end, schdModelDict.end.dateFmt);
                const itemDateWithPre = schdDate.hour(itemPre.hour()).minute(itemPre.minute());
                const itemDateWithEnd = schdDate.hour(itemEnd.hour()).minute(itemEnd.minute());
                if (!summary[groupkey]) {
                    summary[groupkey] = {
                        min: itemDateWithPre,
                        max: itemDateWithEnd,
                        machine: item.machine,
                    };
                } else {
                    if (itemDateWithPre.isBefore(summary[groupkey].min)) {
                        summary[groupkey].min = itemDateWithPre;
                    }
                    if (itemDateWithEnd.isAfter(summary[groupkey].max)) {
                        summary[groupkey].max = itemDateWithEnd;
                    }
                }
            });
            for (const groupkey in summary) {
                summary[groupkey].min = summary[groupkey].min.format('YYYY-MM-DD HH:mm');
                summary[groupkey].max = summary[groupkey].max.format('YYYY-MM-DD HH:mm');
            }
            return summary;
        },
    },
    actions: {
        reset() {
            this.orderList.length = 0;
            this.jobList.length = 0;
            this.schdList.length = 0;
            this.jobLockList.length = 0;
        },
        isTaskRunning() {
            if (!dayjs(this.taskStart).isValid()) return false;
            const now = dayjs();
            return (now.diff(dayjs(this.taskStart), 'second') < this.taskRunMaxSec);
        },
        async loadExcel(file) {
            try {
                const _excelData = await autoTabluar.parseExcelFile(file);
                await this.initData(_excelData);
                this.resetRunningTask();
            } catch (error) {
                notify(`Excel文档解析出现错误!${error.message}`);
                throw error;
            }
        },
        /**
         * @typedef AutoSchdData
         * @property {Array} orderList
         * @property {Array} jobList
         * @property {Array} jobLockList
         * @property {Array} schdList
         */
        /**
         *
         * @param {AutoSchdData} data
         */
        async initData(data) {
            // 加载order-list
            this.orderList.length = 0;
            await this.addOrder(data.orderList);
            this.jobList.length = 0;
            await this.addJobs(data.jobList, true);
            this.jobLockList.length = 0;
            this.addLocks(data.jobLockList);
            this.schdList.length = 0;
            await this.addSchd(data.schdList);
            await this.syncProduct(this.jobList);
            this.syncScheduleSummary(this.jobList);
            this.checkJob(this.jobList);
        },
        deleteOrder(serial_number) {
            const index = this.orderList.findIndex((item) => {
                return item.serial_number === serial_number;
            });
            if (index === -1) throw new Error(`没有找到序号为${serial_number}的订单信息`);
            // 同步删除job
            const order_code = this.orderList[index].order_code;
            const _jbid = this.jobList.filter((item) => {
                return item.order_code === order_code;
            }).map((item) => {
                return item.serial_number;
            });
            _jbid.forEach((item) => {
                this.deleteJob(item);
            });
            this.orderList.splice(index, 1);
        },
        deleteJob(serial_number) {
            // 同步删除已排产数据,和锁定数据
            const index = this.jobList.findIndex((item) => {
                return item.serial_number === serial_number;
            });
            if (index === -1) throw new Error(`没有找到序号为${serial_number}的任务信息`);
            const job_code = this.jobList[index].job_code;
            // 删除已排产数据
            this.schdList.filter((item) => {
                return item.job_code === job_code;
            }).forEach((item) => {
                this.deleteSchd(item.serial_number);
            });
            // 删除锁定数据
            this.jobLockList.filter((item) => {
                return item.job_code === job_code;
            }).forEach((item) => {
                this.deleteLocks(item.serial_number);
            });
            this.jobList.splice(index, 1);
        },
        deleteLocks(serial_number) {
            const index = this.jobLockList.findIndex(item => {
                return item.serial_number === serial_number;
            });
            if (index === -1) {
                console.error('未找到对应的锁定任务');
                return;
            }
            this.jobLockList.splice(index, 1);
        },
        deleteSchd(serial_number) {
            const index = this.schdList.findIndex(item => {
                return item.serial_number === serial_number;
            });
            if (index === -1) {
                console.error('未找到对应的排产单');
                return;
            }
            // 重新更新对应job中的summary
            const job_code = this.schdList[index].job_code;
            this.schdList.splice(index, 1);
            const toSyncJobs = this.jobList.filter((item) => {
                return job_code === item.job_code;
            });
            this.syncScheduleSummary(toSyncJobs);
        },
        /**
         *
         * @param {string} serial_number
         * @param {Object} data
         */
        async updateOrder(serial_number, data) {
            const _order = this.orderList.find((item) => {
                return item.serial_number === serial_number;
            });
            if (!_order) throw new Error(`没有找到序号为${serial_number}的订单信息`);
            Object.keys(data).forEach((key) => {
                _order[key] = data[key];
            });
            await this.syncProduct([_order]);
            this.checkOrder([_order]);
        },
        /**
         *
         * @param {string} serial_number
         * @param {Object} data
         */
        async updateJob(serial_number, data) {
            const _order = this.jobList.find((item) => {
                return item.serial_number === serial_number;
            });
            if (!_order) throw new Error(`没有找到序号为${serial_number}的任务信息`);
            Object.keys(data).forEach((key) => {
                _order[key] = data[key];
            });
            await this.syncProduct([_order]);
            this.checkJob([_order]);
        },
        async addCalcedSchd(calcResult) {
            // 对传递的结果进行展开
            const _jobList = [];
            const _schdList = [];
            const machie = await this.fetchMachines();
            /** @type {array} */
            const macList = machie.data;
            calcResult.forEach((item) => {
                const job = this.jobList.find((job) => {
                    return job.job_code === item.job_code;
                });
                if (job) {
                    item.days.forEach((day) => {
                        const _schd = this.getEmpyRow('shcd');
                        const _date = dayjs(day.date).format('YYYY-MM-DD');
                        const _end = dayjs(day.end, 'HH:mm').format('HH:mm');
                        const _pre = dayjs(day.pre, 'HH:mm').format('HH:mm');
                        const _run = dayjs(day.run, 'HH:mm').format('HH:mm');
                        _schd.job_code = job.job_code;
                        _schd.quantity = job.quantity;
                        _schd.product = job.product;
                        const mac = macList.find((mac) => {
                            return mac.code === item.machine_code;
                        });
                        _schd.machine = mac.name;
                        _schd.end = _end;
                        _schd.pre = _pre;
                        _schd.run = _run;
                        _schd.date = _date;
                        _schd.process_name = job.process_name;
                        _schd.process_cate_name = job.process_cate_name;
                        _schdList.push(_schd);
                    });
                    _jobList.push(job);
                }
            });
            if (_schdList.length > 0) {
                this.schdList.length = 0;
                // 对结果进行排序按照机台,日期+pre
                _schdList.sort((a, b) => {
                    const _a = a.machine + a.date + a.pre;
                    const _b = b.machine + b.date + b.pre;
                    return _a > _b ? 1 : -1;
                });
                await this.addSchd(_schdList);
                this.syncScheduleSummary(_jobList);
            }
        },
        findOrderCodePrd(item) {
            const _orderPrds = this.orderCodePrdsDict[item.order_code];
            if (!Array.isArray(_orderPrds)) return undefined;
            return _orderPrds.find((info) => {
                return info?.code === item?.product_info?.code || info?.name === item?.product_info?.name;
            });
        },
        updatePrdInfo(item, info) {
            item.product_info = info;
        },
        checkJob(items) {
            items.forEach((item) => {
                // 订单号是否存在校验
                this.updateJobOrderCodeErrorTags(item);
                //  产品信息校验
                this.updatePrdErrorTags(item);
                // 工序校验
                this.updateJobProcessErrorTags(item);
                // job与订单产品是否相符合校验
                this.updateJobOrderPrdErrorTags(item);
                this.updateJobDateErrorTags(item);
                this.updateJobCalcDateErrorTags(item);
            });
        },
        checkSchd(items) {
            items.forEach((item) => {
                // 机台检验
                this.updateMacErrorTags(item);
                // 产品检验
                this.updatePrdErrorTags(item);
                // jobcode检验
                this.updateSchdJobErrorTags(item);
            });
        },
        checkLocks(items) {
            items.forEach((item) => {
                // 机台检验
                this.updateMacErrorTags(item);
                // 产品检验
                this.updatePrdErrorTags(item);
                // jobcode检验
                this.updateSchdJobErrorTags(item);
            });
        },
        syncScheduleSummary(jobItems) {
            jobItems.forEach(item => {
                const groupkey = [item.job_code, item.product_info?.id || '', item.process_name].join('-');
                const _summaryItem = this.schedSummary[groupkey];
                if (_summaryItem) {
                    item.machine = _summaryItem.machine;
                    item.job_run = _summaryItem.min;
                    item.job_end = _summaryItem.max;
                } else {
                    item.machine = '';
                    item.job_run = '';
                    item.job_end = '';
                }
            });
        },
        addItems(items, typed) {
            const model = `${typed}Model`;
            const handleList = `${typed}List`;
            const _serial_numbers = items.map((item) => {
                if (!Object.hasOwn(item, 'serial_number') || item.serial_number === '') {
                    item.serial_number = autoTabluar.uniqueId(autoTabluar[model].dataDefined.name);
                }
                this[handleList].push(item);
                return item.serial_number;
            });
            const addItems = this.filterItemBySerialNumbers(typed, _serial_numbers);
            return addItems;
        },
        async addOrder(items) {
            const addItems = this.addItems(items, 'order');
            await this.syncProduct(addItems);
            this.checkOrder(addItems);
        },
        async addJobs(jobs, isLoadByExcelOrERPs = false) {
            // isLoadByExcelOrERPs:判断是否为excel导入或者erp导入
            const addItems = this.addItems(jobs, 'job');
            addItems.forEach((item) => {
                // 判断是否为完成状态
                if (item.is_finished !== '是') {
                    const quantity = Number(item.quantity);
                    const produced_quantity = Number(item.produced_quantity);
                    if (!isNaN(quantity) && !isNaN(produced_quantity)
                        && parseInt(quantity) <= parseInt(produced_quantity)) {
                        item.is_finished = '是';
                    }
                }
                if (item.is_finished === '是') {
                    item.isIncludedInCalculation = false;
                }
            });
            if (!isLoadByExcelOrERPs) {
                await this.syncProduct(addItems);
                this.syncScheduleSummary(addItems);
                this.checkJob(addItems);
            }
        },
        async addSchd(schds) {
            const addItems = this.addItems(schds, 'schd');
            await this.syncProduct(addItems);
            await this.syncMachineInfo(addItems);
            this.checkSchd(addItems);
        },
        addLocks(locks) {
            this.addItems(locks, 'jobLock');
        },
        filterItemBySerialNumbers(typed, serial_numbers) {
            switch (typed) {
            case 'Order':
            case 'order':
                return this.filerOrdersBySerialNumbers(serial_numbers);
            case 'Job':
            case 'job':
                return this.filerJobsBySerialNumbers(serial_numbers);
            case 'schd':
                return this.filerSchdsBySerialNumbers(serial_numbers);
            case 'jobLock':
                return this.filerLockssBySerialNumbers(serial_numbers);
            default:
                throw new Error(`传入的typed:${typed}参数不正确`);
            }
        },
        filerOrdersBySerialNumbers(serial_numbers) {
            return serial_numbers.map((sn) => {
                return this.orderList.find((item) => {
                    return item.serial_number === sn;
                });
            });
        },
        filerJobsBySerialNumbers(serial_numbers) {
            return serial_numbers.map((sn) => {
                return this.jobList.find((item) => {
                    return item.serial_number === sn;
                });
            });
        },
        filerSchdsBySerialNumbers(serial_numbers) {
            return serial_numbers.map((sn) => {
                return this.schdList.find((item) => {
                    return item.serial_number === sn;
                });
            });
        },
        filerLockssBySerialNumbers(serial_numbers) {
            return serial_numbers.map((sn) => {
                return this.jobLockList.find((item) => {
                    return item.serial_number === sn;
                });
            });
        },
        isOrderFoundInJobs(order_code) {
            return this.jobList.find((item) => {
                return item.order_code === order_code;
            });
        },
        getEmpyRow(name) {
            switch (name) {
            case 'orderList':
            case 'order':
                return autoTabluar.mkOrderTpl();
            case 'job':
            case 'jobList':
                return autoTabluar.mkJobTpl();
            case 'jobLock':
            case 'jobLockList':
                return autoTabluar.mkLockTpl();
            case 'shcd':
            case 'schdList':
                return autoTabluar.mkSchdTpl();
            }
        },
        /**
         *
         * @param {Array[Object]} items
         */
        checkOrder(items) {
            // 订单编号重复
            this.updateOrderCodeErrorTags();
            items.forEach(item => {
                // 日期校验
                this.updateOrderDateErrorTags(item);
                // 产品信息校验
                this.updatePrdErrorTags(item);
            });
        },
        async fetchProducts(args) {
            const url = `/api/products/`;
            const params = {};
            if (args.products) {
                params.q = args.products;
            }
            try {
                const res = await axios.get(url, {params});
                if (res.data.error) {
                    throw new Error(res.data.error);
                }
                return res.data;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    // 处理 Axios 错误
                    console.error('Axios Error:', error.message);
                }
                throw error;
            }
        },
        updateOrderCodeErrorTags() {
            this.orderList.forEach((orderData) => {
                this.updateErrorTags(orderData.error,
                    ErrorTags['order-code-repeat'],
                    this.orderCodesCount[orderData.order_code] > 1);
            });
        },
        updateOrderDateErrorTags(orderData) {
            // earliest,latest,shipment_date都不能为空
            this.updateErrorTags(orderData.error,
                ErrorTags['order-date-require'],
                !orderData.earliest || !orderData.latest || !orderData.shipment_date,
            );
            this.updateErrorTags(orderData.error,
                ErrorTags['earliest-format'],
                !dayjs(orderData.earliest, autoTabluar.dateFmt('orderModel', 'earliest')).isValid(),
            );
            this.updateErrorTags(orderData.error,
                ErrorTags['latest-format'],
                !dayjs(orderData.latest, autoTabluar.dateFmt('orderModel', 'latest')).isValid(),
            );
            this.updateErrorTags(orderData.error,
                ErrorTags['shipment-format'],
                !dayjs(orderData.shipment_date,
                    autoTabluar.dateFmt('orderModel', 'shipment_date')).isValid(),
            );
            this.updateErrorTags(orderData.error,
                ErrorTags['earliest-after-latest'],
                dayjs(orderData.earliest).isAfter(dayjs(orderData.latest)),
            );
            this.updateErrorTags(orderData.error,
                ErrorTags['latest-after-shipment'],
                dayjs(orderData.latest).isAfter(dayjs(orderData.shipment_date)),
            );
        },
        updateJobOrderCodeErrorTags(jobData) {
            this.updateErrorTags(jobData.error,
                ErrorTags['order-code-not-found'],
                !Object.hasOwn(this.orderCodePrdsDict, jobData.order_code));
        },
        updateJobOrderPrdErrorTags(jobItem) {
            // 与订单产品进行比较
            const _prd = this.findOrderCodePrd(jobItem);
            this.updateErrorTags(jobItem.error,
                ErrorTags['job-prd-not-equel-order'],
                !_prd);
        },
        updateJobProcessErrorTags(jobData) {
            if (jobData.product_info) {
                this.removeError(jobData.error, 'job-process-not-found');
                const process = jobData.product_info?.craft.find((craft) => {
                    return craft.name === jobData.process_name;
                });
                this.updateErrorTags(jobData.error,
                    ErrorTags['job-process-not-found'],
                    !process,
                );
            }
        },
        updateJobCalcDateErrorTags(jobData) {
            const plan_date = dayjs(this.g_plan_date, 'YYYY-MM-DD');
            const latest_date = dayjs(jobData.latest, 'YYYY-MM-DD');
            if (plan_date.isValid()) {
                this.removeError(jobData.error, 'latest-before-plan');
                this.updateErrorTags(jobData.error,
                    ErrorTags['latest-before-plan'],
                    latest_date.isBefore(plan_date),
                );
            }
        },
        updateJobDateErrorTags(jobData) {
            // earliest,latest,shipment_date都不能为空
            this.updateErrorTags(jobData.error,
                ErrorTags['job-date-require'],
                !jobData.earliest || !jobData.latest,
            );
            this.updateErrorTags(jobData.error,
                ErrorTags['earliest-format'],
                !dayjs(jobData.earliest, autoTabluar.dateFmt('jobModel', 'earliest')).isValid(),
            );
            this.updateErrorTags(jobData.error,
                ErrorTags['latest-format'],
                !dayjs(jobData.latest, autoTabluar.dateFmt('jobModel', 'latest')).isValid(),
            );
            this.updateErrorTags(jobData.error,
                ErrorTags['earliest-after-latest'],
                dayjs(jobData.earliest).isAfter(dayjs(jobData.latest)),
            );
        },
        updatePrdErrorTags(item) {
            this.updateErrorTags(item.error, ErrorTags['product-not-found'],
                item.product_info === null);

            if (item.product_info !== null) {
                this.updateErrorTags(item.error, ErrorTags['product-not-cfg'],
                    item.product_info.state === '未配置');
            }
        },
        updateSchdJobErrorTags(item) {
            // 判断job_code 是否在joblist中
            const err = this.jobList.findIndex(job => job.job_code === item.job_code) === -1;
            this.updateErrorTags(item.error, ErrorTags['job-not-found'],
                err);
        },
        async syncProduct(localItems) {
            // 同步远程产品信息到本土产品信息,重命名product_code
            const localProducts = [...new Set(localItems.map(item => item.product))];
            if (localProducts.length === 0) return;
            const fetchProductsPromise = [];
            // const gsize = 25;
            const serveMaxLength = 3000;
            const prdGroups = localProducts.reduce((acc, cur, index) => {
                if (index === 0) {
                    acc.push([cur]);
                    return acc;
                }
                const _textSize = encodeURI(cur).length
              + encodeURI(acc[acc.length - 1].join(',')).length;
                if (_textSize >= serveMaxLength) {
                    acc.push([cur]);
                } else {
                    acc[acc.length - 1].push(cur);
                }
                return acc;
            }, []);
            prdGroups.forEach((prds) => {
                fetchProductsPromise.push(
                    this.fetchProducts({'products': prds.join(',')}));
            });
            // for (let i = 0; i < localProducts.length; i += gsize) {
            //     fetchProductsPromise.push(
            //         this.fetchProducts({'products': localProducts.slice(i, i + gsize).join(',')}));
            // }
            await Promise.all(fetchProductsPromise)
                .then((products) => {
                    const remoteProducts = products.reduce((acc, cur) => {
                        acc.push(...cur.data);
                        return acc;
                    }, []);
                    localItems.forEach(item => {
                        const remoteProduct = remoteProducts.filter((prd) => {
                            return (prd.code === item.product || prd.name === item.product);
                        });
                        if (remoteProduct.length > 0) {
                            const code = remoteProduct[0].code;
                            const id = remoteProduct[0].id;
                            const name = remoteProduct[0].name;
                            const state = remoteProduct[0]?.data?.['工艺'].length > 0 ? '已配置' : '未配置';
                            const craft = remoteProduct[0]?.data?.['工艺'];
                            item.product_info = {code, id, state, name, craft};
                            this.syncProcessCateName(item);
                        } else {
                            item.product_info = null;
                        }
                    });
                })
                .catch((err) => {
                    throw err;
                });
        },
        syncProcessCateName(item) {
            // 判断item中是否存在process_cate_name 属性,如果不存在则不进行处理
            if ('process_cate_name' in item) {
                const process = item.product_info?.craft.find(craft => {
                    return craft.name === item.process_name;
                });
                if (process) {
                    item.process_cate_name = process.cate_name;
                }
            }
        },
        async syncMachineInfo(localItems) {
            const remoteMacs = await this.fetchMachines();
            localItems.forEach(item => {
                const remoteMac = remoteMacs.data.filter((mac) => {
                    return (mac.code === item.machine || mac.name === item.machine);
                });
                if (remoteMac.length > 0) {
                    const code = remoteMac[0].code;
                    const id = remoteMac[0].id;
                    const name = remoteMac[0].name;
                    item.machine_info = {code, id, name};
                } else {
                    item.machine_info = null;
                }
            });
        },
        updateMacErrorTags(item) {
            this.updateErrorTags(item.error, ErrorTags['mac-not-found'],
                item.machine_info === null);
        },
        removeError(errors, tagToRemove) {
            for (let i = errors.length - 1; i >= 0; i--) {
                if (errors[i].tag === tagToRemove) {
                    errors.splice(i, 1);
                }
            }
        },
        addError(errors, tag, info) {
            errors.push({tag, info});
        },
        updateErrorTags(errors, {tag, info}, isError) {
            this.removeError(errors, tag);
            if (isError) {
                // 添加新的错误标签
                this.addError(errors, tag, info);
            }
        },
        getOrder(serial_number) {
            return this.orderList.find((item) => {
                return item.serial_number === serial_number;
            });
        },
        getJob(serial_number) {
            return this.jobList.find((item) => {
                return item.serial_number === serial_number;
            });
        },
        getSchd(serial_number) {
            return this.schdList.find((item) => {
                return item.serial_number === serial_number;
            });
        },
        getSchedObj() {
            // 将已排产数据进行数据转换,
        },

        beforeAddLockCheck(toLock) {
            // 获取已有的locks信息
            // 根据job_code,machine_info.id,product_info.id,process_name
            const fmt = 'HH:mm';
            const locks = this.getLocks(toLock);
            const toLockPre = dayjs(toLock.pre, fmt);
            const toLockEnd = dayjs(toLock.end, fmt);
            if (locks.length > 0) {
                // 比对时间起始范围是否存在交叉
                for (let i = 0; i < locks.length; i++) {
                    const lockPre = dayjs(locks[i].pre, fmt);
                    const lockEnd = dayjs(locks[i].end, fmt);
                    if (!lockEnd.isValid()) {
                        return {msg: `已有${locks[i].job_code}|${locks[i].date},占据相应的时间段`, isError: true};
                    }
                    // end 或者 pre在lockPre,lockEnd之间存在交叉
                    if (toLockPre.isBefore(lockEnd) && toLockEnd.isAfter(lockPre)) {
                        return {msg: `已有${locks[i].job_code}|${locks[i].date},占据相应的时间段`, isError: true};
                    }
                    // 判断任务单号是否重复
                    if (toLock.job_code === locks[i].job_code) {
                        return {msg: `已有${locks[i].job_code}|${locks[i].date},同一天不要重复锁定`, isError: true};
                    }
                }
            }
            // 如果有冲突则返回错误信息
            return {msg: '', isError: false};
        },
        getLocks(args) {
            return this.jobLockList.filter((item) => {
                return (
                    item.error.length === 0
                    && item.machine_info?.id === args.machine_info.id
                    && dayjs(item.date).isSame(dayjs(args.date)));
            });
        },
        /**
         * @param {string|Date|null} plan_date
        */
        //  * @returns {import('../assets/js/schd.js').calcPostData}
        async mkCalcPostTPl(plan_date) {
            const _pdate = dayjs(plan_date).isValid()
                ? dayjs(plan_date).format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD');
            // if (dayjs(_pdate).isBefore(dayjs(), 'day')) {
            //     throw Error('计划日期不能小于当前日期');
            // }
            const calcPost = {};
            calcPost.options = {
                '开始日期': _pdate,
                '最小时长': 10,
                // 计算的最大时长,单位秒
                'max-time': this.taskRunMaxSec,
            };
            // TODO:目前只支持印刷工序
            const validProcessNames = ['印刷'];
            calcPost.categories = ['print'];
            const schd = this.getSchdLastCode(plan_date);
            const remoteMacs = await this.fetchMachines();
            calcPost.machines = this.mkCalcPostMachines(remoteMacs, schd);
            calcPost.jobs = this.mkPostJobs().filter((item) => {
                return validProcessNames.indexOf(item.process_cate_name) > -1;
            });
            console.log(calcPost.jobs);
            if (calcPost.jobs.length === 0) {
                throw Error('没有相应的任务信息');
            }
            // return calcPost;
            return stringify(calcPost);
        },
        /**
         * @returns {Object<string, {last_code: (string|null)}}
         */
        mkCalcPostMachines(remoteMacs, schd) {
            // 获取所有机台信息
            const calcPostMac = remoteMacs.data.reduce((pre, cur) => {
                pre[cur.code] = {'last_code': schd[cur.code] || null};
                return pre;
            },
            {});
            return calcPostMac;
        },
        getSchdLastCode(plan_date) {
            // 获取schdList中机台中的最后一个排产单号,只计算有效

            const schds = this.schdList.filter((item) => {
                return item.error.length === 0 && dayjs(item.date).isBefore(dayjs(plan_date));
            });
            const gByMacCode = {};
            for (const schd of schds) {
                if (schd.machine_info) {
                    if (!gByMacCode[schd.machine_info.code]) {
                        gByMacCode[schd.machine_info.code] = [];
                    }
                    gByMacCode[schd.machine_info.code].push(schd);
                }
            }
            const result = {};
            for (const [machine, entries] of Object.entries(gByMacCode)) {
                const latestEntry = entries.reduce((maxEntry, currentEntry) => {
                    const _maxd = dayjs(`${maxEntry.date} ${maxEntry.end}`);
                    const _cred = dayjs(`${currentEntry.date} ${currentEntry.end}`);
                    return _maxd.isAfter(_cred) ? maxEntry : currentEntry;
                },
                );
                result[machine] = `${latestEntry.product_info?.code}::${latestEntry.process_name}`;
            }

            return result;
        },
        async fetchMachines() {
            const url = `/api/machines/`;
            try {
                const res = await axios.get(url);
                if (res.data.error) {
                    throw new Error(res.data.error);
                }
                return res.data;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    // 处理 Axios 错误
                    console.error('Axios Error:', error.message);
                }
                throw error;
            }
        },
        /**
         * @returns {Array<import('../assets/js/schd.js').Job>}
         */
        mkPostJobs() {
            return this.jobList.filter(this.isJopPostEnabled).map(job => {
                const quantity = Number(job.quantity);
                let produce_quantity = 0;
                if (!isNaN(Number(job.produce_quantity))) {
                    produce_quantity = Number(job.produce_quantity);
                }
                return {
                    code: job.job_code,
                    product_code: job.product_info?.code || job.product,
                    process_code: job.process_name,
                    process_name: job.process_name,
                    quantity: Math.floor(quantity - produce_quantity),
                    process_cate_name: job.process_cate_name,
                    earliest: dayjs(job.earliest).format('YYYY-MM-DD'),
                    latest: dayjs(job.latest).format('YYYY-MM-DD'),
                };
            });
        },
        isJopPostEnabled(job) {
            // 如果标记为完成则不参与计算
            if (job.is_finished !== '否') return false;
            // 没有错误信息
            if (job.error.length > 0) return false;
            // 标记为不参与计算
            if (!job.isIncludedInCalculation) return false;
            // 数量不是数值类型
            const quantity = Number(job.quantity);
            if (isNaN(quantity)) return false;
            // 数量小于完成量
            const produced_quantity = Number(job.produced_quantity);
            if (!isNaN(produced_quantity) && quantity <= produced_quantity) {
                return false;
            }
            return true;
        },
        /**
         * @returns {Array<import('../assets/js/schd.js').LockInfo>}
         */
        mkPostLocks() {
            // 对机台日期进行分组
            const gLock = this.jobLockList.reduce((acc, job) => {
                if (`${job.job_code}::${job.machine}` in acc) {
                    acc[`${job.job_code}::${job.machine}`].push(job);
                } else {
                    acc[`${job.job_code}::${job.machine}`] = [job];
                }
                return acc;
            }
            , {});
            // 对gLock进行转换
            const locks = [];
            // 对gLock中的每个job按照日期和pre进行排序
            Object.values(gLock).forEach(jobs => {
                const mapped = jobs.map((job, i) => {
                    return {
                        i,
                        value:
                        dayjs(`${dayjs(job.date).format('YYYY-MM-DD')} ${job.pre}`, 'YYYY-MM-DD HH:mm'),
                    };
                });
                mapped.sort((a, b) => {
                    if (a.value.isAfter(b.value)) {
                        return 1;
                    }
                    if (a.value.isBefore(b.value)) {
                        return -1;
                    }
                    return 0;
                });

                const sortedJobs = mapped.map((v) => jobs[v.i]);
                if (sortedJobs.length > 0) {
                    const _job = {};
                    _job.schedule = sortedJobs.map((job) => {
                        return {
                            pre: job.pre,
                            run: job.run,
                            end: job.end,
                            date: dayjs(job.date).format('YYYY-MM-DD'),
                        };
                    });
                    _job.job_code = sortedJobs[0].job_code;
                    _job.machine = sortedJobs[0].machine;
                    locks.push(_job);
                }
            });
            return locks;
        },
        addRunningTask(uuid) {
            this.currentTaskId = uuid;
            this.taskStart = dayjs();
        },
        resetRunningTask() {
            this.currentTaskId = null;
            this.taskStart = null;
        },
        async checkAll() {
            await this.syncProduct(this.orderList);
            await this.syncProduct(this.schdList);
            await this.syncProduct(this.jobList);
            await this.syncProduct(this.jobLockList);
            this.syncScheduleSummary(this.jobList);
            await this.syncMachineInfo(this.jobList);
            await this.syncMachineInfo(this.schdList);
            await this.syncMachineInfo(this.jobLockList);
            this.checkOrder(this.orderList);
            this.checkJob(this.jobList);
            this.checkSchd(this.schdList);
            this.checkLocks(this.jobLockList);
        },
    },
    // persist: {
    //     type: 'storage',
    //     name: 'Vue3PersistStorage',
    //     storeName: 'DataSheet',
    //     key: 'schd-local',
    //     paths: ['orderList', 'jobList', 'jobLockList',
    //         'schdList', 'g_plan_date', 'currentTaskId',
    //         'taskStart', 'taskRunMaxSec', 'storeKey'],
    //     encryption: false,
    //     isOpenExpires: true,
    //     day: 1,
    //     // beforeRestore: () => {
    //     //     console.log('before resotre');
    //     //     ElLoading.service({fullscreen: true});
    //     // },
    //     afterRestore: (plug) => {
    //         plug.store.checkAll();
    //     },
    //     debug: false,
    // },
    persist: {
        storage: localStorage,
        key: 'schd-local',
        paths: ['orderList', 'jobList', 'jobLockList',
            'schdList', 'g_plan_date', 'currentTaskId',
            'taskStart', 'taskRunMaxSec', 'storeKey'],
        // beforeRestore: (ctx) => {
        //     console.log(ctx.store.jobList);
        //     console.log('before,restore');
        //     // ctx.store.checkAll();
        // },
        afterRestore: (ctx) => {
            if (ctx.store.orderList.length > 0) {
                ctx.store.checkAll();
            }
        },
    },
});

export default SchdStore;
export {SchdStore};
