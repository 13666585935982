<template>
    <el-row class="query-row">
        <el-col :span="4">
            <el-input
                v-model="keywords.order_code"
                autocomplete="off"
                placeholder="订单号"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="4">
            <el-input
                v-model="keywords.product"
                autocomplete="off"
                placeholder="产品"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.isError"
                autocomplete="off"
                clearable
                placeholder="错误过滤">
                <el-option label="" value="" />
                <el-option label="无错误" value="0" />
                <el-option label="有错误" value="1" />
            </el-select>
        </el-col>
        <el-col :span="2" />
        <el-col :span="2" />
    </el-row>
    <el-row class="table-container">
        <el-auto-resizer>
            <template #default="{ height, width }">
                <el-table-v2
                    v-model:sort-state="sortState"
                    :columns="columns"
                    :data="orderList"
                    :width="width-165"
                    :height="height-10"
                    class="orderTable"
                    expand-column-key="index"
                    row-key="serial_number"
                    @column-sort="onSort">
                    <template #row="prop">
                        <Row v-bind="prop" />
                    </template>
                </el-table-v2>
            </template>
        </el-auto-resizer>
    </el-row>
    <order-list-edit ref="refOrderEdit" />
    <JobSplitOpt
        :dialog-visible="showJobSplit" :jobs="jobInfo"
        :order-info="orderInfo" @close="showJobSplit=false" />
    <productOpt ref="refProductOpt" />
</template>
<script setup lang="jsx">
import SchdStore from '../stores/schdtabular.js';
import {notify} from '../assets/js/utils.js';
// import {buildProductCraftsGraphList} from '../assets/js/allSchdCalc.js';
import {getErpPrd} from '../assets/js/erp2Schd.js';
import {autoTabluar} from '../assets/js/schd.js';
import {estimateProductPlan} from '../assets/js/allSchdCalc.js';
// import {setPassive} from '../assets/js/setEventPassive.js';
import {h, computed, ref, Fragment, defineProps, watch} from 'vue';
import {
    ElCheckbox, ElText, ElIcon, ElTag,
    TableV2SortOrder, ElMessageBox,
} from 'element-plus';
import {WarningFilled, Refresh, Delete, Edit, Operation, Plus} from '@element-plus/icons-vue';
import OrderListEdit from './order-list-edit.vue';
import JobSplitOpt from './job-split-opt.vue';
import productOpt from './product-opt.vue';
import SwitchFinished from './switch-finished.vue';

const uWbStore = SchdStore();
const orderList = computed(() => {
    uWbStore.orderList.forEach((item) => {
        // @todo将error压缩为一个字符串,然后在生成tag的时候进行解压
        if (item.error.length > 0) {
            item.children = [{'detail': item.error.map((err) => err.info).join('@@')}];
        } else {
            item.children = null;
        }
    });
    const {order_code, product, job_code, machine, isError} = keywords.value;
    const _orderCode = order_code.trim();
    const _product = product.trim();
    const _jobCode = job_code.trim();
    if (_orderCode || _product || _jobCode || machine || isError) {
        return uWbStore.orderList.filter((item) => {
            const matchesOrderCode = !_orderCode || item.order_code.includes(_orderCode);
            const matchesProductName = !_product || item?.product?.includes(_product);
            const matchesJobCode = !_jobCode || item?.job_code?.includes(_jobCode);
            const matchesMachine = !machine || item?.machine?.includes(machine);
            const filterError = isError === ''
                ? true
                : (isError === '0' ? item.error.length === 0 : item.error.length > 0);
            // 只有当所有检查都通过时，才返回 true
            return matchesOrderCode && matchesProductName && matchesJobCode && matchesMachine && filterError;
        });
    }
    return uWbStore.orderList;
});
const props = defineProps({
    isErpSync: {
        type: Boolean,
        default: false,
    },
});
const SelectionCell = ({
    value,
    intermediate = false,
    onChange,
}) => {
    return h(ElCheckbox, {modelValue: value, indeterminate: intermediate, onChange});
};
const indexCell = ({isError, index}) => {
    const icon = isError
        ? () => h(ElIcon, null, {default: () => h(WarningFilled)})
        : null;
    return h(
        ElText,
        {
            class: 'mx-1',
            type: isError ? 'danger' : '',
        },
        {default: () => [index, icon ? icon() : null]},
    );
};
const errorTags = (errorStringList) => {
    const errors = errorStringList.split('@@');
    return h(Fragment, null, errors.map((error) => {
        return h(ElTag, {
            type: 'danger',
            size: 'large',
        }, {default: () => error});
    }));
};

const Row = (obj) => {
    if (obj.rowData.detail) {
        return errorTags(obj.rowData.detail);
    }
    return obj.cells;
};
// 不继承父节点的style属性
Row.inheritAttrs = false;

// 操作列组件逻辑

// 同步产品
const refProductOpt = ref(null);
const syncFromERP = async (row) => {
    const prd = splitStringByLastColon(row.product);
    const prdinfo = await getErpPrd(prd.p_name, prd.v_name);
    if (prdinfo.length === 0) {
        notify('error', '同步出现错误');
        return;
    }
    const data = await refProductOpt.value.show({
        title: '产品同步添加',
        data: prdinfo[0],
        product_list: [],
    });
    if (data) {
        // 刷新
        await uWbStore.updateOrder(row.serial_number, row);
        // 同步处理其他地方的相同的产品的错误信息
        // 处理订单信息的
        const orders = uWbStore.orderList.filter((item) => {
            return (item.product === row.product_info.name
                    || item.product === row.product_info.code) && item.serial_number !== row.serial_number;
        });
        orders.forEach(item => {
            uWbStore.updatePrdInfo(item, row.product_info);
        });
        const jobes = uWbStore.jobList.filter((item) => {
            return (item.product === row.product_info.name
                    || item.product === row.product_info.code);
        });
        jobes.forEach(job => {
            uWbStore.updatePrdInfo(job, row.product_info);
        });
        uWbStore.checkOrder(orders);
        uWbStore.checkJob(jobes);
    }
};
const splitStringByLastColon = (product) => {
    const parts = product.split(':');
    if (parts.length > 1) {
        const p_name = parts.slice(0, -1).join(':');
        const v_name = parts[parts.length - 1];
        return {
            p_name,
            v_name,
        };
    } else {
        return {
            p_name: product,
            v_name: '',
        };
    }
};

// op功能
// 删除
const deleteOrder = (row) => {
    ElMessageBox.confirm(
        `删除本行数据？${row.order_code},同步删除任务与排产`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            uWbStore.deleteOrder(row.serial_number);
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
// 批量删除
const delOrders = () => {
    const toDelOrders = orderList.value.filter((item) => item.checked);
    ElMessageBox.confirm(
        `删除${toDelOrders.length}行数据？`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            toDelOrders.forEach((item) => {
                uWbStore.deleteOrder(item.serial_number);
            });
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
// 编辑订单
const refOrderEdit = ref(null);
const editOrder = async (row) => {
    // const prdlist = uWbStore.orderList.map(item => item.product);
    // const ppds = await buildProductCraftsGraphList([row.product]);
    // const ppd = ppds[0];
    // ppd.calculateSchedule(row.shipment_date, Number(row.quantity), 'post');
    // ppd.calculateSchedule('2024-09-27', Number(row.quantity), 'future');
    const res = await refOrderEdit.value.show({
        title: '订单修改',
        data: Object.assign({}, row),
    });
    if (res) {
        // 更新数据
        uWbStore.updateOrder(row.serial_number, res);
    }
};

// 拆分订单
const jobInfo = ref([]);
const orderInfo = ref({});
const showJobSplit = ref(false);
const splitProcess = ['print'];
/**
 * 根据传入的参数过滤拆分的任务列表信息
 * @param {array} jobList
 * @param {string[]|string} rule -['print']|all|process_cate_code
 * @returns {array}
 */
const jobsfilter = (jobList, rule = ['print']) => {
    if (rule === 'all') {
        return jobList;
    } else if (Array.isArray(rule)) {
        return jobList.filter(job => rule.includes(job.cate_code));
    } else {
        return jobList.filter(job => job.cate_code === rule);
    }
};
const addToJob = async (row) => {
    const _orderData = uWbStore.getOrder(row.serial_number);
    const {
        product_info: {name},
        quantity, earliest: plan_date, latest,
    } = _orderData;
    try {
        // 已最迟日期来进行推算
        const [craftList, estimateSchLast] = await estimateProductPlan(name, latest, quantity, 'post');
        const [, estimateSchEarliest] = await estimateProductPlan(name, plan_date, quantity, 'future');

        // 拆分好的任务单列表
        const splitJobList = craftList.map((craft) => {
            if (estimateSchLast.has(craft.name)) {
                // job.earlist_date = estimateSchLast.get(job.name).begin;
                const _es = estimateSchLast.get(craft.name);
                craft.latest_finished = _es.end;
                craft.time = _es.producedminutes / 60;
                craft.produce_quantity = craft.cate_code === 'mkbag'
                    ? _es.processQuantity.output
                    : _es.processQuantity.input;
                craft.produce_quantity = Math.floor(craft.produce_quantity);
                console.log(_es);
            }
            if (estimateSchEarliest.has(craft.name)) {
                const _es = estimateSchEarliest.get(craft.name);
                craft.earlist_date = _es.begin;
                // job.latest_finished = estimateSchLast.get(job.name).end;
                // job.time = estimateSchLast.get(job.name).producedminutes / 60;
            }
            return craft;
        });
        // 替换joblist中的 earlist_date,latest_finished
        // 本期只开发印刷任务
        // 判单任务列表是否存在相应的任务
        const _jobL = jobsfilter(splitJobList, splitProcess)
            .filter((item) => {
                return (orderJobObj[_orderData.order_code] || []).findIndex((pname) => {
                    return pname === item.name;
                }) === -1;
            });
        if (_jobL.length === 0) {
            notify('info', '没有需要拆分的任务', 3000);
            return;
        }
        // 对joblist进行处理,过滤已有的任务
        jobInfo.value = [..._jobL];
        // this.jobInfo = [...joblist];
        // this.jobInfo = joblist.filter((item) => item.cate_code === 'print');
        // 对jobinfo进行处理,保证数量都为整数
        orderInfo.value = {..._orderData};
        showJobSplit.value = true;
    } catch (e) {
        console.log('订单拆分出现错误!', e);
        notify(e);
    }
};
// 判断是否可以拆分订单
const forbid = (row) => {
    if (row.forbidSplit) return true;
    if (row.error.length > 0) return true;
    if (row.is_finished === '是') return true;
    return false;
};
const orderJobObj = computed(() => {
    return uWbStore.jobList.reduce((acc, cur) => {
        if (!Object.hasOwn(acc, cur.order_code)) {
            acc[cur.order_code] = [];
        }
        acc[cur.order_code].push(cur.process_name);
        return acc;
    }, {});
});
watch(orderJobObj, (val) => {
    uWbStore.orderList.forEach((item) => {
        if (val[item.order_code]) {
            item.forbidSplit = jobsfilter(item.product_info?.craft || [], splitProcess).reduce((acc, cur) => {
                return acc && val[item.order_code].findIndex((pname) => {
                    return pname === cur.name;
                }) !== -1;
            }, true);
        } else {
            item.forbidSplit = false;
        }
    });
});

// 添加订单
const addOrder = async () => {
    const _tpl = uWbStore.getEmpyRow('orderList');
    autoTabluar.doOrderFmtData(_tpl);
    const res = await refOrderEdit.value.show({
        title: '订单添加',
        data: _tpl,
    });
    if (res) {
        // 添加数据
        uWbStore.addOrder([res]);
    }
};
// 排序功能
const sortState = ref({
    'earliest': TableV2SortOrder.ASC,
    'latest': TableV2SortOrder.ASC,
    'shipment_date': TableV2SortOrder.ASC,
});

const onSort = ({key, order}) => {
    sortState.value[key] = order;
    const _direction = sortState.value[key] === TableV2SortOrder.ASC ? 1 : -1;
    uWbStore.orderList.sort((a, b) => {
        if (a[key] !== b[key]) {
            return (a[key] > b[key] ? 1 : -1) * _direction;
        }
        return 0;
    });
};

// 查询功能
const keywords = ref({
    'order_code': '',
    'product': '',
    'machine': '',
    'job_code': '',
    'isError': '',
});

const isPrdNotFound = ({error}) => {
    return error.findIndex((err) => {
        return err.tag === 'product-not-found';
    }) > -1;
};
const switchCell = (info) => {
    const order = orderList.value.find((item) => item.serial_number === info.serial_number);
    order[info.key] = info.value;
};
const columns = [
    {
        key: 'selection',
        width: 40,
        class: 'table-col',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            const onChange = (value) => (rowData.checked = value);
            return SelectionCell({value: rowData.checked, onChange});
        },
        headerCellRenderer: () => {
            const onChange = (value) => {
                orderList.value.forEach((item) => { item.checked = value; return item; });
            };
            const allSelected = orderList.value.every((row) => row.checked);
            const containsChecked = orderList.value.some((row) => row.checked);
            return SelectionCell({
                value: allSelected,
                intermediate: containsChecked && !allSelected,
                onChange,
            });
        },
    },
    {
        width: 80,
        title: '序号',
        key: 'index',
        class: 'table-col',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData, rowIndex}) => {
            return indexCell({isError: rowData.error.length > 0, index: rowIndex + 1});
        },
    },
    {
        key: 'order_code',
        title: '订单号',
        dataKey: 'order_code',
        width: 120,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
    },
    {
        key: 'product',
        title: '产品',
        dataKey: 'product',
        width: 450,
        class: 'table-col product',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            return <>
                {props.isErpSync && isPrdNotFound(rowData)
                    ? (<el-tooltip
                        content="同步产品" placement="left"
                        show-after={500}
                    >
                        <el-button
                            link
                            type="primary"
                            size="small"
                            onClick={() => { syncFromERP(rowData); }}>
                            <el-icon><Refresh /></el-icon>
                        </el-button>
                    </el-tooltip>)
                    : null
                }
                <div class="el-table-v2__cell-text"
                    style="white-space: pre" title={rowData.product}>{ rowData.product }</div>
            </>;
        },
    },
    {
        key: 'quantity',
        title: '数量',
        dataKey: 'quantity',
        width: 120,
        class: 'table-col col-right',
        headerClass: 'job-table-header',
    },
    {
        key: 'earliest',
        title: '最早开始日期',
        dataKey: 'earliest',
        width: 150,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
        sortable: true,
    },
    {
        key: 'latest',
        title: '最迟完成日期',
        dataKey: 'latest',
        width: 150,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
        sortable: true,
    },
    {
        key: 'shipment_date',
        title: '交货期',
        dataKey: 'shipment_date',
        width: 150,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
        sortable: true,
    },
    {
        key: 'is_finished',
        title: '完成',
        dataKey: 'is_finished',
        width: 100,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            return <SwitchFinished
                key={`isfished-${rowData.serial_number}`}
                state={rowData.is_finished}
                serialNumber={rowData.serial_number}
                onSwitch={switchCell}
            />;
        },
    },
    {
        key: 'remark',
        title: '备注',
        dataKey: 'remark',
        width: 150,
        class: 'table-col',
        headerClass: 'job-table-header',
    },
    {
        width: 150,
        class: 'table-col',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            return <>
                <el-tooltip content="编辑订单" placement="left" show-after={1000}>
                    <el-button
                        link
                        type="primary"
                        size="large"
                        onClick={() => { editOrder(rowData); }}>
                        <el-icon><Edit /></el-icon>
                    </el-button>
                </el-tooltip>
                <el-tooltip
                    content="拆分订单" placement="right"
                    show-after={1000}>
                    {forbid(rowData)
                        ? (<el-button
                            link
                            type="primary"
                            size="large"
                            disabled={true}
                        >
                            <el-icon><Operation /></el-icon>
                        </el-button>)
                        : (<el-button
                            link
                            type="primary"
                            size="large"
                            onClick={() => { addToJob(rowData); }}>
                            <el-icon><Operation /></el-icon>
                        </el-button>)}
                </el-tooltip>
                <el-tooltip content="删除" placement="top" show-after={1000}>
                    <el-button
                        link
                        type="danger"
                        size="large"
                        onClick={() => { deleteOrder(rowData); }}>
                        <el-icon><Delete /></el-icon>
                    </el-button>
                </el-tooltip>
            </>;
        },
        headerCellRenderer: () => {
            return <>
                <el-button
                    onClick={addOrder}>
                    <el-icon><Plus /></el-icon>
                </el-button>
                {orderList.value.some((item) => item.checked)
                    ? <el-button
                        type="danger"
                        size="large"
                        onClick={delOrders}>
                        <el-icon><Delete /></el-icon>
                    </el-button>
                    : null
                }
            </>;
        },
    },
];
</script>
<style>
.table-col {
    border-left: 1px solid #ebeef5;
}
.col-center {
    justify-content: center;
}
.col-right {
    justify-content: flex-end;
}
.col-left {
    justify-content: flex-start;
}
.el-table-v2__row>.table-col:last-of-type {
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
}
.product {
    font-size:0.8rem;
    text-align: left;
    text-wrap:nowrap;
}
.job-table-header {
    background-color:#f5f7fa !important;
    color:#606266 !important;
    border:1px solid #ebeef5;
    text-align: center;
    justify-content: center;
}
.query-row {
    height: 50px;
}
.table-container {
    height: calc(100% - 50px);
}
</style>
